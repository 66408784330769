import { MapContainer, TileLayer, Marker, useMap ,FeatureGroup,Popup,Circle} from 'react-leaflet';
import L from 'leaflet';
import { useEffect } from 'react';
import 'leaflet/dist/leaflet.css';
import markerSvg from './marker.svg';

const icon = new L.Icon({
	iconUrl: markerSvg,
	iconRetinaUrl: markerSvg,
	iconAnchor: null,
	popupAnchor: null,
	shadowUrl: null,
	shadowSize: null,
	shadowAnchor: null,
	iconSize: new L.Point(60, 75),
});
const purpleOptions = { color: 'purple' };
function ResetCenterView(props) {
	const { selectPosition } = props;
	const map = useMap();

	useEffect(() => {
		if (selectPosition && !isNaN(selectPosition?.lat)) {
			map.setView(L.latLng(selectPosition?.lat, selectPosition?.lng), map.getZoom(), {
				animate: true,
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectPosition]);
	return null;
}
const MapSales = (props) => {
	const { position ,rent_cost,deposit_cost} = props;
	var pos;
	pos = [20.5937, 78.9629];

	if (position?.lat != undefined || position?.lng != undefined) {
		// eslint-disable-next-line react-hooks/exhaustive-deps
		pos = [position.lat, position.lng];
	}
	console.log(position);
	return (
		<MapContainer zoom={13} center={pos} id="map" style={{ height: '100%', width: '100%' }}>
			<TileLayer
				attribution='&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>, &copy; <a href="https://carto.com/attributions">CARTO</a>'
				url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
			/>
{position?.lat != undefined && <FeatureGroup pathOptions={purpleOptions}>
				<Popup>Deposit: {deposit_cost}  Rent: {rent_cost}</Popup>
				<Circle center={pos} radius={1200} />
			</FeatureGroup>}
			
			<ResetCenterView selectPosition={position} />
		</MapContainer>
	);
};

export default MapSales;
