import { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { PageHeader, message, Row, Col, Card } from 'antd';
import useAxiosPrivate from 'hooks/use-axios-private';

import { MailFilled, PhoneFilled } from '@ant-design/icons';
import "../Layout/assets/styles.scss"
import MapinfoUser from 'components/LeafletMap/MapinfoUser.jsx';



export default function UserInfo() {
	const { id } = useParams();
	const navigate = useNavigate();
	const [owner, setOwner] = useState([]);
	const [property, setProperty] = useState([]);
	const [markerPopup,setMarkerPopup] =useState(false);
	const [position,setPosition]=useState([])
	const [clickedposition,setClickedPosition]=useState([]);
	const axiosPrivate = useAxiosPrivate();
	const fetchData = async () => {
		try {
			const res = await axiosPrivate.get(`/user`);
			const res2 =
				await axiosPrivate.get(`https://ucpro-ucpoxback.herokuapp.com/api/v3/pox?publishedBy=${id}
			`);
			console.log(res2)
			console.log(res2?.data.poxes)
			setProperty(res2?.data.poxes)

			await Promise.all(
				res.data.users.map(async ({ uid, photoURL, displayName, email }) => {
					if (id === uid) {
						setOwner({
							key: uid,
							userName: displayName,
							contactNumber: (
								await axios.get(
									`https://ucpro-ucpoxback.herokuapp.com/api/v3/pox?publishedBy=${uid}`
								)
							).data?.poxes[0]?.contact_number,
                           
							email,
							photoURL,
						});

						
					}
				})
				
			);
			
				// res2.data.poxes.map((pox,id)=>{
				// 	setPosition([...position,{
				// 		position:pox
				// 	}])

				// })
				for(let id =0;id<res2?.data.poxes.length;id=id+1){
					setPosition(current =>[...current,res2?.data.poxes[id].position])

				}
			
		} catch (error) {
			message.error(error?.response?.data?.message || error?.message);
		}
	};
	useEffect(() => {
		fetchData();
		console.log(property)
		console.log(position)

		// console.log(owner);
		// console.log(property);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	},[]);
	


	return (
		<div style={{ height: '100%' }}>
			<Row>
				<Col span={24}>
					<Card
						style={{
							
							marginTop: '90px',
							marginLeft: '20px',
							marginRight: '20px',
							borderRadius: '12px',
						}}
						loading={(owner?.contactNumber)?false:true}
					>
						<PageHeader
							className="site-page-header"
							onBack={() => navigate(-1)}
							// title={`User ${data?.profile?.user_name}`}
							title="User details"
						/>
						<Row gutter={16}>
							<Col span={12}>
								<Row>
									<Col span={24}>
										<div
											style={{
												display: 'flex',
												alignItems:"center",
												width: '90%',
												height: '99px',
												border: '1px solid #808080',
												borderRadius: '10px',
												margin: '30px',
											}}
										>
											<img
												src={owner?.photoURL}
												alt="ownerimg"
												style={{ borderRadius: '50%', width: '60px', height: '60px' ,margin:"10px"}}
											/>
											<div style={{ display: 'flex', flexDirection: 'column' }}>
												<h1>{owner?.userName}</h1>
												<h1 className='user-detail-headings'>Owner</h1>
											</div>
										</div>
									</Col>
									<Col span={24}>
										<div
											style={{
												display:"flex",
												flexDirection:"column",
												justifyContent:"center",
												width: '90%',
												height: '99px',
												border: '1px solid #808080',
												borderRadius: '10px',
												margin: '30px',
											}}
										>
											<h1 className="user-detail-headings">Contact Details</h1>
											<div style={{marginLeft:"30px"}}>
												<div style={{display:"flex",alignItems:"center"}}><PhoneFilled/><h1 style={{marginLeft:"10px"}}>{owner?.contactNumber}</h1></div>
												<div style={{display:"flex",alignItems:"center"}}><MailFilled/><h1 style={{marginLeft:"10px"}}>{owner?.email}</h1></div>
											</div>
										</div>
									</Col>
									<Col span={24}>
										<div
											style={{
												width: '90%',
												border: '1px solid #808080',
												borderRadius: '10px',
												margin: '30px',
											}}
										>
											<h1 className="user-detail-headings">Properties Owned</h1>
											{property.map((prop,id) => {
												return (<div key={id} style={{marginLeft:"30px",color:"#4274C4",cursor:"pointer"}} onClick={()=>{setMarkerPopup(true)
												setClickedPosition(prop.position)}}>{prop.name}</div>)
											})}
										</div>
									</Col>
								</Row>
							</Col>
							<Col span={12}>
								<h1 className="user-detail-headings2">Property Location</h1>
								<div
									style={{
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center',
										padding: '30px',
										height:"88.5%",
										border: '1px solid #808080',
										borderRadius: '10px',
									}}
								>
									{' '}
									<div
										style={{
											width: '100%',
											height: '100%',
											zIndex: 1,
											border: '1px solid #808080',
										}}
									>
										<MapinfoUser position={position} markerPopup={markerPopup} clickedposition={clickedposition}/>
									</div>
								</div>
							</Col>
						</Row>
					</Card>
				</Col>
			</Row>
		</div>
	);
}
