import { Fragment } from 'react';
import '../Layout/assets/styles.scss';
import commingSoon from '../Layout/assets/commingSoon.png';

export default function Settings() {

	return (
		<Fragment>
			<div className="settings-page-outer">
				<div className="settings-page-inner">
					<h1 className="settings-heading">Comming Soon</h1>

					<img src={commingSoon} alt="comming-soon" className="settings-image"></img>
				</div>
			</div>
		</Fragment>
	);
}
