import { useEffect, useState, Fragment } from 'react';
import { useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import {
	Button,
	Space,
	Divider,
	PageHeader,
	Row,
	Col,
	Switch,
	message,
	Typography,
	Skeleton,
} from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import useAxiosPrivate from 'hooks/use-axios-private';
import { getUser } from 'redux/features/auth';
import Mapinfo from 'components/LeafletMap/Mapinfo.jsx';
import { onRemove } from 'utils/firebase/storage';

export default function PoxInfo() {
	const { id } = useParams();
	const navigate = useNavigate();
	const user = useSelector(getUser);
	const [isLoading, setIsLoading] = useState(true);
	const [data, setData] = useState(null);
	const axiosPrivate = useAxiosPrivate();

	// delete location
	const deletePox = async () => {
		try {
			data.images.forEach((img) => onRemove(img));
			const res = await axiosPrivate.delete(`/pox/${id}`);
			return res;
		} catch (err) {
			message.error(err?.response?.data?.message || err?.message);
		}
	};

	const handleDelete = async () => {
		if (!window.confirm('Are you sure you want to delete this pox')) return;
		const res = await deletePox();
		message.success(res?.data?.message);
		navigate('/poxes');
	};

	// toggle status
	const toggleStatus = async () => {
		const status = data?.status === 'active' ? 'deactivated' : 'active';
		try {
			await axiosPrivate.patch(`/pox/${id}`, { status });
			message.info(`Pox status changed to ${status}`);
			setData((prev) => ({
				...prev,
				status,
			}));
		} catch (err) {
			message.error(err?.response?.data?.message || err?.message);
		}
	};

	useEffect(() => {
		const fetchData = async () => {
			setIsLoading(true);
			try {
				const res = await axiosPrivate.get(`/pox/${id}`);
				setData(res.data.pox);
			} catch (error) {
				message.error(error?.response?.data?.message || error.message);
			} finally {
				setIsLoading(false);
			}
		};
		fetchData();

		return () => {
			setData(null);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Fragment>
			<div style={{ display: 'flex', alignItems: 'center', marginBottom: 20 }}>
				<PageHeader
					className="site-page-header"
					style={{ flexGrow: 1 }}
					onBack={() => navigate(-1)}
					title={data?.name}
				/>
				{!user?.isAdmin && data && (
					<Space>
						<Button
							type="primary"
							icon={<EditOutlined />}
							onClick={() => navigate(`/poxes/${id}/edit`)}
						/>
						<Button type="danger" icon={<DeleteOutlined />} onClick={handleDelete} />
					</Space>
				)}
			</div>

			<Row>
				{isLoading ? (
					<Fragment>
						<Col span={12} style={{ padding: '0 24px' }}>
							{Array.from(Array(4)).map((e) => (
								<Skeleton key={e} active />
							))}
						</Col>
						<Col span={12}>
							<Skeleton.Image atcive />
						</Col>
					</Fragment>
				) : (
					<Fragment>
						<Col span={12} style={{ padding: '0 24px' }}>
							<h5>Publisher's Info</h5>
							<p>
								<Typography.Text type="secondary">Full name</Typography.Text>:&nbsp;
								{data?.publishedBy.displayName}
							</p>
							<p>
								<Typography.Text type="secondary">Email</Typography.Text>:&nbsp;
								{data?.publishedBy.email}
							</p>
							<Divider />
							<h5>Contact Details</h5>
							<p>
								<Typography.Text type="secondary">Contact name</Typography.Text>:&nbsp;
								{data?.contact_name}
							</p>
							<p>
								<Typography.Text type="secondary">Contact number</Typography.Text>:&nbsp;
								{data?.contact_number}
							</p>
							<Divider />
							<h5>Property's Info</h5>
							<p>
								<Typography.Text type="secondary">Property Type</Typography.Text>:&nbsp;
								{data?.type}
							</p>
							{data?.room_config && (
								<p>
									<Typography.Text type="secondary">Room Config</Typography.Text>:&nbsp;
									{data.room_config}
								</p>
							)}
							{data?.plot_config && (
								<p>
									<Typography.Text type="secondary">Plot Config</Typography.Text>:&nbsp;
									{data.plot_config}
								</p>
							)}
							{data?.facing && (
								<p>
									<Typography.Text type="secondary">Entrance Direction</Typography.Text>
									:&nbsp;
									{data.facing}
								</p>
							)}
							{data?.floor && (
								<p>
									<Typography.Text type="secondary">Floor</Typography.Text>:&nbsp;{' '}
									{data.floor}
								</p>
							)}
							{data?.bath && (
								<p>
									<Typography.Text type="secondary">Bath</Typography.Text>:&nbsp;{' '}
									{data.bath}
								</p>
							)}
							<p>
								<Typography.Text type="secondary">Area</Typography.Text>:&nbsp;
								{data?.area?.toLocaleString() + ' sqr ft'}
							</p>
							<p>
								<Typography.Text type="secondary">Description</Typography.Text>:&nbsp;
								{data?.description}
							</p>
							<Divider />
							<h5>Cost</h5>
							<p>
								<Typography.Text type="secondary">Payment Mode</Typography.Text>:&nbsp;
								{data?.pay_type}
							</p>
							{data?.deposit_cost && (
								<p>
									<Typography.Text type="secondary">Deposit</Typography.Text>:&nbsp;
									{data.deposit_cost.toLocaleString()}
								</p>
							)}
							{data?.rent_cost && (
								<p>
									<Typography.Text type="secondary">Rent</Typography.Text>:&nbsp;
									{data.rent_cost.toLocaleString()}
								</p>
							)}
							{data?.lease_cost && (
								<p>
									<Typography.Text type="secondary">Lease</Typography.Text>:&nbsp;
									{data.lease_cost.toLocaleString()}
								</p>
							)}
							{data?.lease_period && (
								<p>
									<Typography.Text type="secondary">Lease Duration</Typography.Text>:&nbsp;
									{data.lease_period.toLocaleString() + ' days'}
								</p>
							)}
							{data?.sale_cost && (
								<p>
									<Typography.Text type="secondary">Selling Price</Typography.Text>:&nbsp;
									{data.sale_cost.toLocaleString()}
								</p>
							)}
							{data?.turnover_cost && (
								<p>
									<Typography.Text type="secondary">Turnover</Typography.Text>:&nbsp;
									{data.turnover_cost.toLocaleString()}
								</p>
							)}
						</Col>
						<Col span={12}>
							{!user?.isAdmin && (
								<div
									style={{
										width: '100%',
										display: 'flex',
										justifyContent: 'flex-end',
										marginBottom: 32,
									}}
								>
									{data && (
										<Switch
											checkedChildren="Active"
											unCheckedChildren="Deactivated"
											checked={data?.status === 'active' ? true : false}
											onChange={toggleStatus}
										/>
									)}
								</div>
							)}

							{/* map select area */}
							<div
								style={{
									width: '100%',
									height: '350px',
									backgroundColor: '#f2f3f8',
									marginBottom: 16,
								}}
							>
								{data && <Mapinfo position={data?.position} />}
							</div>
							{data?.address && (
								<Typography.Text>
									<Typography.Text type="secondary">Address</Typography.Text>:&nbsp;
									{data.address}
								</Typography.Text>
							)}
						</Col>
					</Fragment>
				)}
			</Row>
		</Fragment>
	);
}
