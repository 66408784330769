import { useState, useEffect, Fragment } from 'react';
import { Link,  useNavigate } from 'react-router-dom';
import axios from 'apis/axios';
import { Table, PageHeader, Space, Input, message } from 'antd';
import useAxiosPrivate from 'hooks/use-axios-private';
const { Search } = Input;

export default function UserList() {
	const [users, setUsers] = useState([]);
	const navigate = useNavigate();
	const [searchTerm,setSearchTerm]=useState('')

	const axiosPrivate = useAxiosPrivate();

	const fetchData = async () => {
		try {
			const res = await axiosPrivate.get(`/user`);
			console.log(res);
		
			setUsers(
				await Promise.all(
					res.data.users.map(async ({ uid, email, displayName, photoURL, metadata }) => ({
						key: uid,
						propertyOwned: (await axios.get(`/pox?publishedBy=${uid}`)).data?.poxes[0]?.type,
						userName: displayName,
						email,
						poxes: await axios.get(`/pox?publishedBy=${uid}`),
						contactNumber: (
							await axios.get(`/pox?publishedBy=${uid}`)
						).data?.poxes[0]?.contact_number,
						status: 'Active',
						photoURL,
						since: new Date(metadata.creationTime).toLocaleDateString('en-us', {
							year: 'numeric',
							month: 'short',
						}),
					}))
				)
			);
		} catch (error) {
			message.error(error?.response?.data?.message || error?.message);
		}
	};
	const onSearch = (value) => {
		setSearchTerm(value)
		
};

	useEffect(() => {
		fetchData();

		return () => {
			setUsers([]);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const columns = [
		{
			// user_name
			title: 'Name',
			dataIndex: 'userName',
			key: 'userName',
			render: (text) => <div>{text}</div>,
		},
		{ title: 'Property Owned', dataIndex: 'propertyOwned', key: 'propertyOwned' },
		{
			// email
			title: 'Email I.D',
			dataIndex: 'email',
			key: 'email',
		},
		// {
		// 	// since
		// 	title: 'Since',
		// 	dataIndex: 'since',
		// 	key: 'since',
		// },
		// {
		// 	// poxes
		// 	title: 'Poxes',
		// 	dataIndex: 'poxes',
		// 	key: 'poxes',
		// },
		// {
		// 	// status
		// 	title: 'Status',
		// 	dataIndex: 'status',
		// 	filters: [
		// 		{
		// 			text: 'Active',
		// 			value: 'Active',
		// 		},
		// 		{
		// 			text: 'Inactive',
		// 			value: 'Inactive',
		// 		},
		// 		{
		// 			text: 'Blocked',
		// 			value: 'Blocked',
		// 		},
		// 	],
		// 	onFilter: (value, record) => record.status.indexOf(value) === 0,
		// },
		{ title: 'Contact Number', dataIndex: 'contactNumber', key: 'contactNumber' },
		{
			title: 'Action',
			key: 'action',
			render: (text, record) => <Link to={record.key}>View</Link>,
		},
	];

	return (
		<Fragment>
			<PageHeader className="site-page-header" onBack={() => navigate(-1)} title="Users" />
			<div style={{ display: 'flex', justifyContent: 'center' }}>
				<div style={{ display: 'flex', alignItems: 'center', marginRight: '100px' }}>
					<h1 className="users-headings2" style={{ marginRight: '20px' }}>
						Reg Users
					</h1>
					<h1
						className="users-data"
						style={{
							width: '165px',
							height: '64px',
							border: '1px solid #808080',
							borderRadius: '6px',
							display:"flex",
							alignItems:"center",justifyContent:"center"
						}}
					>
						860
					</h1>
				</div>
				<div style={{ display: 'flex', alignItems: 'center', margin: '20px' }}>
					<h1 className="users-headings2" style={{ marginRight: '20px' }}>
						Total visitors
					</h1>
					<h1
						className="users-data"
						style={{
							width: '165px',
							height: '64px',
							border: '1px solid #808080',
							borderRadius: '6px',
							display:"flex",
							alignItems:"center",justifyContent:"center"
						}}
					>
						1300
					</h1>
				</div>
			</div>
			<div style={{ display: 'flex', justifyContent: 'center', margin: '20px' }}>
				{' '}
				<Space>
					<Search
						placeholder="Enter name, property owned"
						size="large"
						enterButton
						style={{ width: 704 }}
						onSearch={(e) => {
							onSearch(e);
						}}
						onChange={(e)=>setSearchTerm(e.target.value)}
					/>
				</Space>
				{/* <h1
					style={{
						color: '#17185c',
						width: '80px',
						height: '40px',
						padding: '5px',
						textAlign: 'center',
						background: 'rgba(23, 24, 92, 0.1)',
						borderRadius: '6px',
						margin: '15px',
					}}
				>
					Sort by
				</h1> */}
			</div>
			<div>
				<Table columns={columns} dataSource={users.filter((item)=>{
					return item?.userName.toLowerCase().includes(searchTerm.toLowerCase())
				})} loading={users.length>0?false:true}/>
			</div>
		</Fragment>
	);
}
