import { MapContainer, TileLayer, Marker, Tooltip } from 'react-leaflet';
import { CloseOutlined, DownOutlined } from '@ant-design/icons';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import markerSvg from './marker.svg';
import { useEffect, useState } from 'react';

const icon = new L.Icon({
	iconUrl: markerSvg,
	iconRetinaUrl: markerSvg,
	iconAnchor: null,
	shadowUrl: null,
	shadowSize: null,
	shadowAnchor: null,
	iconSize: new L.Point(60, 75),
});

const MapinfoUser = (props) => {
	const { position, markerPopup, clickedposition } = props;
	console.log(position);
	console.log(clickedposition);

	return (
		<MapContainer
			zoom={1}
			center={position[0]}
			id="map"
			style={{ height: '100%', width: '100%' }}
		>
			<TileLayer
				attribution='&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>, &copy; <a href="https://carto.com/attributions">CARTO</a>'
				url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
			/>
			{position.map((pos, ind) => {
				return (
					<Marker key={ind} position={pos} icon={icon}>
						{pos.lat === clickedposition.lat && markerPopup ?(
							<Tooltip direction="bottom" offset={[0, 20]} opacity={1} permanent>

								This is the clicked property
							</Tooltip>
						):null}
					</Marker>
				);
			})}
		</MapContainer>
	);
};

export default MapinfoUser;
