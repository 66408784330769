import { useState } from 'react';

// Styling components
import { Button } from 'antd';
import Icon from '@ant-design/icons';
import logo from './assets/logo.png';
import globe from './assets/globe.png';
import { ReactComponent as GoogleSvg } from './assets/Google.svg';
import './assets/style.css';

// Loader component
import Loader from 'components/Loader';

// actions
import { logInWithGoogle } from 'utils/firebase/auth';

export default function GoogleOauth() {
	const [loading, setLoading] = useState(false);

	const handleGoogleLogin = async () => {
		setLoading(true);
		try {
			await logInWithGoogle();
		} catch (error) {
			console.error(error);
		} finally {
			setLoading(false);
		}
	};

	if (!loading)
		return (
			<div className="section-auth">
				<div className="section-logo">
					<img src={logo} alt="logo" />
					{/* <p>AI based Property exchange platform</p> */}
				</div>

				<div className="section-forms">
					<div className="view-img">
						<img src={globe} alt="Globe Img" />
					</div>

					<div className="form-control">
						<div className="header-text">Welcome to UCPOX</div>

						<div className="text">Create an Account</div>
						<div className="login-btn">
							<Button
								size="large"
								style={{ height: 58, borderRadius: 8 }}
								icon={<Icon component={GoogleSvg} style={{ fontSize: '1.5em' }} />}
								onClick={handleGoogleLogin}
							>
								Login with Google
							</Button>
						</div>

						{/* <div className="divider">
							<hr></hr>
							<span>OR</span>
							<hr></hr>
						</div> */}

						{/* <div className="form-section">
							<div className="flex-group">
								<div className="input-group">
									<span>+91</span>
									<input
										type="text"
										name="phone_number"
										id="phone_number"
										placeholder="000 0000000"
									/>
								</div>

								<button className="btn-submit">Send OTP</button>
							</div>

							<div className="flex-group">
								<div className="input-group input-form">
									<input type="text" name="otp" id="otp" placeholder="Enter OTP" />
								</div>

								<button className="btn-submit btn-outline">Resend OTP</button>
							</div>

							<div className="submit-section">
								<button className="btn-submit btn-lg">Sign In</button>
							</div>
						</div> */}
					</div>
				</div>
			</div>
		);

	return <Loader />;
}
