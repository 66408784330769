import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import axios from 'apis/axios';

import { Button, Card, Col, Dropdown, Menu, message, Row, Table } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { getUser } from 'redux/features/auth';
import useAxiosPrivate from 'hooks/use-axios-private';

import Map from 'components/LeafletMap';
import {
	LineChart,
	ResponsiveContainer,
	Legend,
	Tooltip,
	Line,
	YAxis,
	CartesianGrid,
} from 'recharts';
export default function Home() {
	const user = useSelector(getUser);

	// const { user, token } = useSelector((state) => state.auth);
	const [data, setData] = useState(null);
	const axiosPrivate = useAxiosPrivate();

	const [salesdata, setSalesdata] = useState([]);
	const [poxes, setPoxes] = useState([]);
	const [aflat, setAflat] = useState(0);
	const [aplot, setAplot] = useState(0);

	const [aestab, setAestab] = useState(0);

	const [iflat, setIflat] = useState(0);
	const [iplot, setIplot] = useState(0);

	const [iestab, setIestab] = useState(0);
	const [month,setMonth] = useState()

	const columns = [
		{
			title: <h1 style={{ fontSize: '19px' }}>Propertyname</h1>,
			dataIndex: 'Propertyname',
			key: 'Propertyname',
		},
		{
			title: <h1 style={{ fontSize: '19px' }}>Property type</h1>,
			dataIndex: 'Propertytype',
			key: 'Propertytype',
		},
		{
			title: <h1 style={{ fontSize: '19px' }}>Customer name</h1>,
			dataIndex: 'Customername',
			key: 'Customername',
		},
		{
			title: <h1 style={{ fontSize: '19px' }}>Sale type</h1>,
			dataIndex: 'Saletype',
			key: 'Saletype',
		},
		{
			title: <h1 style={{ fontSize: '19px' }}>Price</h1>,
			dataIndex: 'Price',
			key: 'Price',
		},
		{
			title: <h1 style={{ fontSize: '19px' }}>Status</h1>,
			dataIndex: 'Status',
			key: 'Status',
		},
	];

	const pdata = [
		{ Flat: 11, Plot: 120, Estab: 130 },
		{ Flat: 9, Plot: 130, Estab: 150 },
		{ Flat: 20, Plot: 150, Estab: 190 },
		{ Flat: 10, Plot: 160, Estab: 210 },
	];

	useEffect(() => {
		const fetchData = async () => {
			try {
				if (user.email === 'admin@ucpox.com') {
					const {
						data: { poxes },
					} = await axios.get(`/pox`);
					const {
						data: { users },
					} = await axiosPrivate.get(`/user`);
					await axios.get(`/pox`).then((res) => {
						setPoxes(res.data.poxes);
						setSalesdata(
							res.data.poxes.map((e) => ({
								key: e._id,
								Propertyname: e.name,
								Propertytype: e.type,
								Customername: 'Yash',
								Saletype: e.sale_type,

								Price: '1000',
								Status: 'Paid',
								createdAt:e.createdAt
							}))
						);
					});
					const res2 = await axios.get(`/pox?publishedBy=${user.uid}`).then(() => {
						console.log(res2);
					});
					setData([
						{
							label: 'Plots',
							url: 'poxes',
							amount: poxes.filter((e) => e.type === 'Plot').length,
						},
						{
							label: 'Flats',
							url: 'poxes',
							amount: poxes.filter((e) => e.type === 'Flat').length,
						},
						{
							label: 'Estabs',
							url: 'poxes',
							amount: poxes.filter((e) => e.type === 'Estab').length,
						},
						{ label: 'Reg. Users', url: 'users', amount: users.length },
						{ label: 'Online Users', url: 'user', amount: 0 },
					]);
				}
			} catch (error) {
				message.error(error?.response?.data?.message || error?.message);
			}
		};
		fetchData();
		
		return () => {
			setData([]);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	console.log(poxes);

	useEffect(() => {
		const values = () => {
			var af = 0,
				ap = 0,
				ae = 0,
				ifl = 0,
				ip = 0,
				ie = 0;
			poxes?.forEach((pox) => {
				if (pox.type === 'Flat' && pox.status === 'active') {
					af = af + 1;
				}
				if (pox.type === 'Plot' && pox.status === 'active') {
					ap = ap + 1;
				}
				if (pox.type === 'Estab' && pox.status === 'active') {
					ae = ae + 1;
				}
				if (pox.type === 'Flat' && pox.status === 'inactive') {
					ifl = ifl + 1;
				}
				if (pox.type === 'Plot' && pox.status === 'inactive') {
					ip = ip + 1;
				}
				if (pox.type === 'Estab' && pox.status === 'inactive') {
					ie = ie + 1;
				}
			});
			setAflat(af);
			setAplot(ap);
			setAestab(ae);
			setIflat(ifl);
			setIplot(ip);
			setIestab(ie);
		};
		values();
	}, [poxes]);

	console.log(data);
	console.log(poxes);
	const menu = (
		<Menu
			items={[
				{
					key: '1',
					label: <h4 onClick={()=>{setMonth(1) 
						}}>Jan</h4>,
					
				},
				{
					label:<h4 onClick={()=>{setMonth(2) 
					}}>Feb</h4>,
					key: '2',
				},
				{
					label:<h4 onClick={()=>{setMonth(3) 
					}}>March</h4>,
					key: '3',
				},
				{
					label: <h4 onClick={()=>{setMonth(4) 
					}}>April</h4>,
					key: '4',
				},
				{
					label: <h4 onClick={()=>{setMonth(5) 
					}}>May</h4>,
					key: '5',
				},
				{
					label: <h4 onClick={()=>{setMonth(6) 
					}}>June</h4>,
					key: '6',
				},
				{
					label: <h4 onClick={()=>{setMonth(7) 
					}}>July</h4>,
					key: '7',
				},
				{
					label: <h4 onClick={()=>{setMonth(8) 
					}}>Aug</h4>,
					key: '8',
				},
				{
					label: <h4 onClick={()=>{setMonth(9) 
					}}>Sept</h4>,
					key: '9',
				},
				{
					label: <h4 onClick={()=>{setMonth(10) 
					}}>Oct</h4>,
					key: '10',
				},
				{
					label: <h4 onClick={()=>{setMonth(11) 
					}}>Nov</h4>,
					key: '11',
				},
				{
					label: <h4 onClick={()=>{setMonth(12) 
					}}>Dec</h4>,
					key: '12',
				},
			]}
		/>
	);
	useEffect(()=>{
		setMonth(parseInt(salesdata[0]?.createdAt.slice(salesdata[0]?.createdAt.indexOf('-')+1,salesdata[0]?.createdAt.indexOf('-')+3)))
		console.log(month)
	},[parseInt(salesdata[0]?.createdAt.slice(salesdata[0]?.createdAt.indexOf('-')+1,salesdata[0]?.createdAt.indexOf('-')+3))])

	return (
		<>
			<div style={{ width: '98%' }}>
				<Row gutter={40}>
					<Col span={9}>
						<Card
							style={{
								width: '100%',
								textAlign: 'center',
								borderRadius: '10px',
							}}
						>
							<h1 className="dashboard-heading1">Total active Poxes</h1>
							<Row gutter={[16]}>
								<Col span={8}>
									<Card
										style={{
											width: '100%',
											boxShadow: '0px 2px 4px 2px rgba(23, 24, 92, 0.2)',

											borderRadius: '10px',
										}}
										loading={poxes.length > 0 ? false : true}
									>
										<p
											style={{
												lineHeight: '28px',
												fontWeight: 500,
												fontSize: '28px',
												color: '#17185C',
											}}
										>
											{aflat}
										</p>
										<h1>Flat</h1>
									</Card>
								</Col>
								<Col span={8}>
									<Card
										style={{
											width: '100%',

											boxShadow: '0px 2px 4px 2px rgba(23, 24, 92, 0.2)',
											borderRadius: '10px',
										}}
										loading={poxes.length > 0 ? false : true}
									>
										<p
											style={{
												lineHeight: '28px',
												fontWeight: 500,
												fontSize: '28px',
												color: '#17185C',
											}}
										>
											{aplot}
										</p>
										<h1>Plot</h1>
									</Card>
								</Col>
								<Col span={8}>
									<Card
										style={{
											width: '100%',

											boxShadow: '0px 2px 4px 2px rgba(23, 24, 92, 0.2)',
											borderRadius: '10px',
										}}
										loading={poxes.length > 0 ? false : true}
									>
										<p
											style={{
												lineHeight: '28px',
												fontWeight: 500,
												fontSize: '28px',
												color: '#17185C',
											}}
										>
											{aestab}
										</p>
										<h1>Estab</h1>
									</Card>
								</Col>
							</Row>
						</Card>
					</Col>
					<Col span={9}>
						<Card
							style={{
								width: '100%',
								textAlign: 'center',
								borderRadius: '10px',
							}}
						>
							<h1 className="dashboard-heading1">Total inactive Poxes</h1>

							<Row gutter={16}>
								<Col span={8}>
									<Card
										style={{
											width: '100%',
											boxShadow: '0px 2px 4px 2px rgba(23, 24, 92, 0.2)',
											borderRadius: '10px',
										}}
										loading={poxes.length > 0 ? false : true}
									>
										<p
											style={{
												lineHeight: '28px',
												fontWeight: 500,
												fontSize: '28px',
												color: '#17185C',
											}}
										>
											{iflat}
										</p>
										<h1>Flat</h1>
									</Card>
								</Col>
								<Col span={8}>
									<Card
										style={{
											width: '100%',
											boxShadow: '0px 2px 4px 2px rgba(23, 24, 92, 0.2)',
											borderRadius: '10px',
										}}
										loading={poxes.length > 0 ? false : true}
									>
										<p
											style={{
												lineHeight: '28px',
												fontWeight: 500,
												fontSize: '28px',
												color: '#17185C',
											}}
										>
											{iplot}
										</p>
										<h1>Plot</h1>
									</Card>
								</Col>
								<Col span={8}>
									<Card
										style={{
											width: '100%',
											boxShadow: '0px 2px 4px 2px rgba(23, 24, 92, 0.2)',
											borderRadius: '10px',
										}}
										loading={poxes.length > 0 ? false : true}
									>
										<p
											style={{
												lineHeight: '28px',
												fontWeight: 500,
												fontSize: '28px',
												color: '#17185C',
											}}
										>
											{iestab}
										</p>
										<h1>Estab</h1>
									</Card>
								</Col>
							</Row>
						</Card>
					</Col>
					<Col span={6}>
						<Link to="users">
							<Card
								style={{
									width: '100%',
									textAlign: 'center',
									borderRadius: '10px',
								}}
							>
								<h1 className="dashboard-heading1">Total Reg Users</h1>
								<Card
									style={{
										width: '100%',
										boxShadow: '0px 2px 4px 2px rgba(23, 24, 92, 0.2)',
										borderRadius: '10px',
									}}
									loading={poxes.length > 0 ? false : true}
								>
									<p
										style={{
											height: '28px',
											lineHeight: '28px',
											fontWeight: 500,
											fontSize: '28px',
											color: '#17185C',
										}}
									>
										{data && data[3]?.amount}
									</p>

									<h1>Users</h1>
								</Card>
								{/* <h1 className="dashboard-heading1">Total Reg Users</h1>
								
									<div
										style={{
											height:"108px",
											fontWeight: 500,
											fontSize: '28px',
											color: '#17185C',
											lineHeight: '34px',
											marginTop: '40px',
										}}
									>
										{data && <p>{data[3]?.amount}</p>}
									</div> */}
							</Card>
						</Link>
					</Col>
				</Row>
				<Row gutter={[30, 16]} style={{ marginTop: '15px' }}>
					<Col span={12}>
						<Card style={{ borderRadius: '10px' }}>
							<div
								style={{
									display: 'flex',
									width: '100%',
									justifyContent: 'space-between',
									marginBottom: '10px',
								}}
							>
								<h1
									className="dashboard-heading2"
									style={{ display: 'flex', alignItems: 'center' }}
								>
									Total Sales
									<span
										style={{
											fontWeight: 500,
											fontSize: '23px',
											color: '#17185C',
											marginLeft: '10px',
										}}
									>
										$2000
									</span>
								</h1>

								<div>
									<Dropdown overlay={menu} >
										<Button
											style={{
												background: 'rgba(23, 24, 92, 0.06)',
												borderRadius: '30px',

												width: '150px',
												height: '40px',
											}}
										>
											<div
												style={{
													display: 'flex',
													alignItems: 'center',
													justifyContent: 'space-between',
												}}
											>
												<h1 style={{ margin: '4px' }}>Month</h1>

												<DownOutlined />
											</div>
										</Button>
									</Dropdown>
								</div>
							</div>

							<div style={{ display: 'flex', height: '250px' }}>
								<div className="salesGraph" style={{ width: '60%', height: '100%' }}>
									<ResponsiveContainer
										className="responsiveContainer"
										style={{ width: '100%h', height: '100%' }}
										aspect={2}
									>
										<LineChart data={pdata} style={{ width: '100%', height: '100%' }}>
											<CartesianGrid />
											<YAxis></YAxis>
											<Legend />
											<Tooltip />
											<Line dataKey="Flat" stroke="blue" activeDot={{ r: 8 }} />
											<Line dataKey="Plot" stroke="yellow" activeDot={{ r: 8 }} />
											<Line dataKey="Estab" stroke="red" activeDot={{ r: 8 }} />
										</LineChart>
									</ResponsiveContainer>
								</div>

								<div style={{ margin: '20px', width: '40%' }}>
									<Row>
										<Col span={12}>
											<div style={{ display: 'flex', flexDirection: 'column' }}>
												<div style={{ display: 'flex', alignItems: 'center' }}>
													<h1 style={{ margin: '10px' }}>Flat</h1>
												</div>

												<h1
													style={{
														color: '#4274C4',
														background: 'rgba(66, 116, 196, 0.08)',
														borderRadius: '4px',
														width: '40%',
														textAlign: 'center',
														marginLeft: '19px',
													}}
												>
													$1100
												</h1>
											</div>
										</Col>
										<Col span={12}>
											<div style={{ display: 'flex', flexDirection: 'column' }}>
												<div style={{ display: 'flex', alignItems: 'center' }}>
													<h1 style={{ margin: '10px' }}>Plot</h1>
												</div>

												<h1
													style={{
														color: '#FFD30B',
														background: 'rgba(255, 211, 11, 0.15)',
														borderRadius: '4px',
														width: '40%',
														textAlign: 'center',
														marginLeft: '19px',
													}}
												>
													$450
												</h1>
											</div>
										</Col>
										<Col span={12}>
											<div style={{ display: 'flex', flexDirection: 'column' }}>
												<div style={{ display: 'flex', alignItems: 'center' }}>
													<h1 style={{ margin: '10px' }}>Estab</h1>
												</div>

												<h1
													style={{
														color: '#F24867',
														background: 'rgba(242, 72, 103, 0.08)',
														borderRadius: '4px',
														width: '40%',
														textAlign: 'center',
														marginLeft: '19px',
													}}
												>
													$450
												</h1>
											</div>
										</Col>
									</Row>
								</div>
							</div>
						</Card>
					</Col>
					<Col span={12}>
						<Link to="sales">
							<div style={{ width: '100%' }}>
								<Card style={{ width: '100%', borderRadius: '10px' }}>
									<h1 className="dashboard-heading2" style={{ height: '47px' }}>
										Sales by Region
										
										

										
										
										
										
									</h1>
									<div style={{ width: '100%' }}>
										<div
											style={{
												width: '80%',
												height: '250px',
												marginLeft: 'auto',
												marginRight: 'auto',
											}}
										>
											
											<Map />
										</div>
									</div>
								</Card>
							</div>
						</Link>
					</Col>
					<Col span={24}>
						<Card style={{ borderRadius: '10px' }}>
							<div
								style={{
									display: 'flex',
									width: '100%',
									justifyContent: 'space-between',
									marginBottom: '10px',
								}}
							>
								<h1
									className="dashboard-heading3"
									style={{ display: 'flex', alignItems: 'center' }}
								>
									Sales report
								</h1>
								<div>
									{' '}
									<Dropdown overlay={menu}>
										<Button
											style={{
												background: 'rgba(23, 24, 92, 0.06)',
												borderRadius: '30px',
												width: '150px',
												height: '40px',
											}}
										>
											<div
												style={{
													display: 'flex',
													alignItems: 'center',
													justifyContent: 'space-between',
												}}
											>
												<h1 style={{ margin: '4px' }}>Month</h1>

												<DownOutlined />
											</div>
										</Button>
									</Dropdown>
								</div>
							</div>
							<Table
								columns={columns}

								dataSource={salesdata.filter((item)=>{
									return parseInt(item?.createdAt.slice(item?.createdAt.indexOf('-')+1,item?.createdAt.indexOf('-')+3)) === month
								})}
								loading={salesdata.length > 0 ? false : true}
							/>
						</Card>
					</Col>
				</Row>
			</div>
		</>
	);
}
