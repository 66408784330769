import { Routes, Route } from 'react-router-dom';
import './styles/index.scss';

import { AlreadyLoggedIn, PersistLogin, RequireAuth } from 'components';
// Auth pages
import UserAuthPage from 'pages/UserAuth';
import AdminLoginPage from 'pages/AdminAuth';

// layout
import Layout from 'pages/Dashboard/Layout';

// general pages
import NotFound from 'pages/404';
import HomePage from 'pages/Dashboard/HomePage';
import SettingsPage from 'pages/Dashboard/SettingsPage';

// users
import UsersDefaultPage from 'pages/Dashboard/UserDefault';
import UserInfoPage from 'pages/Dashboard/UserInfo';

// poxes
import PoxDefaultPage from 'pages/Dashboard/PoxDefault';
import PoxInfoPage from 'pages/Dashboard/PoxInfo';
import PoxNewPage from 'pages/Dashboard/PoxNew';
import PoxEditPage from 'pages/Dashboard/PoxEdit';
import Poxverif from 'pages/Dashboard/Poxverif';
import Verifpox from 'pages/Dashboard/Verifpox';
import SalesRegion from 'pages/Dashboard/SalesRegion';
import ChangePassword from 'pages/ChangePassword';

const MyApp = () => {
	return (
		<Routes>
			<Route element={<PersistLogin />}>
				<Route element={<RequireAuth />}>
					<Route path="/" element={<Layout />}>
						<Route index element={<HomePage />} />
						<Route path="poxes" element={<PoxDefaultPage />} />
						<Route path="poxes/new" element={<PoxNewPage />} />
						<Route path="poxes/owned" element={<PoxDefaultPage />} />
						<Route path="poxes/:id" element={<PoxInfoPage />} />
						<Route path="poxes/:id/edit" element={<PoxEditPage />} />
						<Route path="settings" element={<SettingsPage />} />
					</Route>
				</Route>

				<Route element={<RequireAuth requiresAdmin />}>
					<Route path="/" element={<Layout />}>
						<Route path="users" element={<UsersDefaultPage />} />

						<Route path="users/:id" element={<UserInfoPage />} />
						<Route path="poxes/poxverif/:id" element={<Poxverif />} />
						<Route path="poxes/verifpox/:id" element={<Verifpox />} />
						<Route path="sales" element={<SalesRegion />} />
					</Route>

					<Route path="change-password" element={<ChangePassword />} />
				</Route>

				<Route element={<AlreadyLoggedIn />}>
					<Route path="/login" element={<UserAuthPage />} />
					<Route path="/admin" element={<AdminLoginPage />} />
				</Route>
			</Route>

			<Route path="*" element={<NotFound />} />
		</Routes>
	);
};

export default MyApp;
