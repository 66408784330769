import React, { useEffect } from 'react';
import { useState } from 'react';
import MapSales from 'components/LeafletMap/MapSales';
import { Link } from 'react-router-dom';
import { Card, Col, PageHeader, Row, Space, Input ,message} from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import axios from 'apis/axios';

const { Search } = Input;

export default function SalesRegion() {
	const [data, setData] = useState([]);
	const [pos,setPos] = useState({})
	const [dc,setDC] = useState({})
	const [rc,setRC] = useState({})



	const searchRegion = (e) => {
		data.map((d)=>{
			if(d.address===e){
				setPos(d?.position)
				setDC(d?.deposit_cost)
				setRC(d?.rent_cost)
				console.log(pos)
				
			}
		})
	};
	
	
	const fetchData = async () => {
		try {
				
				await axios.get(`/pox`).then((res) => {
					console.log(res);
					setData(
						res.data.poxes.map((e) => ({
							key: e._id,
							id: e.id,
							name: e.name,
							type: e.type,
							date: e.createdAt,
							status: e.status,
							userId: e.publishedBy.displayName,
							address:e.address,
							position:e.position,
							rent_cost:e.rent_cost,
							deposit_cost:e.deposit_cost
						}))
					);
				});
		} catch (error) {
			message.error(error?.response?.data?.message || error?.message);
		}
	};
	useEffect(() => {
		fetchData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	useEffect(() => {
        
		console.log(pos)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pos]);
	return (
		<div style={{ width: '98%' }}>
			<Row>
				<Col span={24}>
					<div style={{ display: 'flex', alignItems: 'center' }}>
						<Link to="/">
							<ArrowLeftOutlined />
						</Link>

						<PageHeader className="site-page-header" title="Dashboard" />
					</div>

					<Card style={{ height: '80vh', borderRadius: '10px' }}>
						<div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
							<h1 className="dashboard-heading2" style={{ width: '30%' }}>
								Sales by Region
							</h1>
							<Space>
								<Search
									placeholder="Search Region"
									size="large"
									enterButton
									style={{ width: 604 }}
									onSearch={(e) => {
										searchRegion(e);
									}}
								/>
							</Space>
						</div>

						<div
							style={{
								width: '96%',
								height: '70vh',
								position: 'absolute',
								zIndex: 1,
							}}
						>
							<MapSales position={pos} deposit_cost={dc} rent_cost={rc}/>
						</div>
					</Card>
				</Col>
			</Row>
		</div>
	);
}
