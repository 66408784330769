import { MapContainer, TileLayer, Marker } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import markerSvg from './marker.svg';

const icon = new L.Icon({
	iconUrl: markerSvg,
	iconRetinaUrl: markerSvg,
	iconAnchor: null,
	popupAnchor: null,
	shadowUrl: null,
	shadowSize: null,
	shadowAnchor: null,
	iconSize: new L.Point(60, 75),
});

const Mapinfo = (props) => {
	const { position } = props;

	return (
		<MapContainer zoom={8} center={position} id="map" style={{ height: '100%', width: '100%' }}>
			<TileLayer
				attribution='&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>, &copy; <a href="https://carto.com/attributions">CARTO</a>'
				url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
			/>

			<Marker position={position} icon={icon}></Marker>
		</MapContainer>
	);
};

export default Mapinfo;
