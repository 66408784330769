import { createElement } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Layout, Menu, Badge, Space, Button, Tooltip, Dropdown, Typography } from 'antd';
import {
	MenuUnfoldOutlined,
	MenuFoldOutlined,
	BellOutlined,
	MailOutlined,
	MoreOutlined,
	UserOutlined,
	LogoutOutlined,
} from '@ant-design/icons';

import { logOutSuccess } from 'redux/features/auth';
import {
	CustomAccSettingsIcon,
	CustomBalanceIcon,
	CustomInboxIcon,
	CustomUserIcon,
} from 'components/Icons';
import { logOut } from 'utils/firebase/auth';

export default function Header({ user, collapsed, toggle, width }) {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	// logout handler
	const logoutHandler = async () => {
		try {
			await logOut();
			dispatch(logOutSuccess());
			navigate('/login', { replace: true });
		} catch (error) {
			console.error(error);
		}
	};

	return (
		<Layout.Header
			className="site-layout-background"
			style={{ padding: '0 16px 0 0', display: 'flex', justifyContent: 'space-between' }}
		>
			{createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
				className: 'trigger',
				onClick: toggle,
			})}

			{width <= 480 ? (
				<Dropdown
					overlay={
						<Menu
							items={[
								{ key: '1', icon: <MailOutlined />, label: 'Message' },
								{ key: '2', icon: <BellOutlined />, label: 'Notifications' },
								{ key: '3', icon: <UserOutlined />, label: 'My Profile' },
								{
									key: '4',
									icon: <LogoutOutlined />,
									onClick: logoutHandler,
									label: 'Log Out',
								},
							]}
						/>
					}
					placement="bottomRight"
					trigger={['click']}
				>
					<Button type="text" icon={<MoreOutlined style={{ fontSize: 20 }} />} />
				</Dropdown>
			) : (
				<Space size={16}>
					<Tooltip title="Messages">
						<Badge count={0}>
							<Button shape="circle" icon={<MailOutlined />} />
						</Badge>
					</Tooltip>
					<Tooltip title="Notifications">
						<Badge count={2}>
							<Button shape="circle" icon={<BellOutlined />} />
						</Badge>
					</Tooltip>

					<Dropdown
						overlay={
							<Menu
								className="custom-menu"
								items={[
									{
										key: '1',
										icon: <CustomInboxIcon />,
										label: (
											<Typography.Text style={{ fontSize: '1.1em', paddingLeft: 20 }}>
												Inbox
											</Typography.Text>
										),
									},
									{
										key: '2',
										icon: <CustomBalanceIcon />,
										label: (
											<Typography.Text style={{ fontSize: '1.1em', paddingLeft: 20 }}>
												Balance
											</Typography.Text>
										),
									},
									{
										key: '3',
										icon: <CustomAccSettingsIcon />,
										label: (
											<Typography.Text
												style={{ fontSize: '1.1em', paddingLeft: 21 }}
												onClick={() => user?.isAdmin && navigate('/change-password')}
											>
												{user?.isAdmin ? 'Change Password' : 'Account Settings'}
											</Typography.Text>
										),
									},
									{
										key: '4',
										icon: <LogoutOutlined style={{ fontSize: '1.5em' }} />,
										onClick: logoutHandler,
										label: (
											<Typography.Text style={{ fontSize: '1.1em', paddingLeft: 13 }}>
												Log out
											</Typography.Text>
										),
									},
								]}
							/>
						}
						trigger={['click']}
						placement="bottomRight"
						style={{ display: 'flex', alignItems: 'center' }}
					>
						<Button
							type="link"
							icon={<CustomUserIcon />}
							style={{ height: '100%', display: 'flex', alignItems: 'center', gap: 10 }}
						>
							<Space direction="vertical" size={0} align="start">
								<Typography.Text>{user.displayName}</Typography.Text>
								<Typography.Text style={{ fontSize: '0.85em' }}>
									{user.email}
								</Typography.Text>
							</Space>
						</Button>
					</Dropdown>
				</Space>
			)}
		</Layout.Header>
	);
}
