import { useState, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from 'utils/firebase/auth';
import { getUser, logInSuccess } from 'redux/features/auth';
import { Loader } from 'components';
import axios from 'apis/axios';

const PersistLogin = () => {
	const [isLoading, setisLoading] = useState(true);
	const dispatch = useDispatch();
	const user = useSelector(getUser);

	useEffect(() => {
		const refresh = async () =>
			onAuthStateChanged(auth, async (userCred) => {
				setisLoading(true);
				try {
					if (userCred) {
						const { data } = await axios.post('/auth', {
							token: await userCred.getIdToken(),
						});

						dispatch(
							logInSuccess({
								user: {
									uid: userCred.uid,
									displayName: userCred.displayName,
									email: userCred.email,
									phoneNumber: userCred.phoneNumber,
									photoURL: userCred.photoURL,
									isAdmin: data.user.isAdmin,
								},
								token: await userCred.getIdToken(),
							})
						);
					}
					setisLoading(false);
				} catch (err) {
					console.error(err);
					setisLoading(false);
				}
			});
		!user && refresh();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return isLoading ? <Loader /> : <Outlet />;
};

export default PersistLogin;
