import Icon from '@ant-design/icons';

const component = () => (
	<svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
		<rect x="0.5" y="0.5" width="49" height="49" rx="9.5" fill="white" />
		<mask
			id="mask0_1237_393"
			style={{ maskType: 'alpha' }}
			maskUnits="userSpaceOnUse"
			x="5"
			y="-1"
			width="40"
			height="45"
		>
			<path
				d="M25.0002 41.764C34.9053 41.764 42.935 32.678 42.935 21.4699C42.935 10.2618 34.9053 1.17578 25.0002 1.17578C15.0951 1.17578 7.06543 10.2618 7.06543 21.4699C7.06543 32.678 15.0951 41.764 25.0002 41.764Z"
				fill="white"
				stroke="white"
				strokeWidth="3"
			/>
		</mask>
		<g mask="url(#mask0_1237_393)">
			<g filter="url(#filter0_d_1237_393)">
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M25.0003 30.0815C29.8028 30.0815 33.696 25.6762 33.696 20.2419C33.696 14.8076 29.8028 10.4023 25.0003 10.4023C20.1978 10.4023 16.3047 14.8076 16.3047 20.2419C16.3047 25.6762 20.1978 30.0815 25.0003 30.0815Z"
					fill="#7FB6F6"
				/>
				<path
					d="M32.066 20.2427C32.066 24.6581 28.9028 28.2373 25.0008 28.2373C21.0987 28.2373 17.9355 24.6581 17.9355 20.2427C17.9355 15.8273 21.0987 12.248 25.0008 12.248C28.9028 12.248 32.066 15.8273 32.066 20.2427Z"
					stroke="#02306A"
					strokeWidth="3"
				/>
			</g>
			<g filter="url(#filter1_d_1237_393)">
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M25.0006 58.3712C32.8046 58.3712 39.131 51.2125 39.131 42.3819C39.131 33.5512 32.8046 26.3926 25.0006 26.3926C17.1965 26.3926 10.8701 33.5512 10.8701 42.3819C10.8701 51.2125 17.1965 58.3712 25.0006 58.3712Z"
					fill="#7FB6F6"
				/>
				<path
					d="M37.501 42.3827C37.501 50.1944 31.9046 56.5271 25.001 56.5271C18.0974 56.5271 12.501 50.1944 12.501 42.3827C12.501 34.5709 18.0974 28.2383 25.001 28.2383C31.9046 28.2383 37.501 34.5709 37.501 42.3827Z"
					stroke="#02306A"
					strokeWidth="3"
				/>
			</g>
		</g>
		<rect x="0.5" y="0.5" width="49" height="49" rx="9.5" stroke="#003169" />
		<defs>
			<filter
				id="filter0_d_1237_393"
				x="15.3047"
				y="10.4023"
				width="25.3916"
				height="27.6797"
				filterUnits="userSpaceOnUse"
				colorInterpolationFilters="sRGB"
			>
				<feFlood floodOpacity="0" result="BackgroundImageFix" />
				<feColorMatrix
					in="SourceAlpha"
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"
				/>
				<feOffset dx="3" dy="4" />
				<feGaussianBlur stdDeviation="2" />
				<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" />
				<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1237_393" />
				<feBlend
					mode="normal"
					in="SourceGraphic"
					in2="effect1_dropShadow_1237_393"
					result="shape"
				/>
			</filter>
			<filter
				id="filter1_d_1237_393"
				x="9.87012"
				y="26.3926"
				width="36.2607"
				height="39.9785"
				filterUnits="userSpaceOnUse"
				colorInterpolationFilters="sRGB"
			>
				<feFlood floodOpacity="0" result="BackgroundImageFix" />
				<feColorMatrix
					in="SourceAlpha"
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"
				/>
				<feOffset dx="3" dy="4" />
				<feGaussianBlur stdDeviation="2" />
				<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" />
				<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1237_393" />
				<feBlend
					mode="normal"
					in="SourceGraphic"
					in2="effect1_dropShadow_1237_393"
					result="shape"
				/>
			</filter>
		</defs>
	</svg>
);

const CustomUserIcon = (props) => <Icon {...{ props, component }} />;

export default CustomUserIcon;
