import { useState } from 'react';
import { Button, Form, Input, message, Typography } from 'antd';
import useAxiosPrivate from 'hooks/use-axios-private';
import { useNavigate } from 'react-router-dom';
import { logOut } from 'utils/firebase/auth';
import { logOutSuccess } from 'redux/features/auth';
import { useDispatch } from 'react-redux';

const ChangePassword = () => {
	const [isLoading, setIsLoading] = useState(false);
	const axiosPrivate = useAxiosPrivate();
	const navigate = useNavigate();
	const dispatch = useDispatch();

	// antd form rule to compare passwords are the same
	const comparePasswords = ({ getFieldValue }) => ({
		validator(_, value) {
			if (!value || getFieldValue('newPassword') === value) {
				return Promise.resolve();
			}
			return Promise.reject(new Error('The two passwords that you entered do not match!'));
		},
	});

	const onFinish = async (values) => {
		setIsLoading(true);
		try {
			const res = await axiosPrivate.post('/auth/change-password', values);
			message.success(res.data.message + ' please login again');
			await logOut();
			dispatch(logOutSuccess());
			navigate('/admin', { replace: true });
		} catch (error) {
			const err = error.response?.data?.message || error.message;
			message.error(err);
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<main
			style={{
				minHeight: '100%',
				display: 'grid',
				placeItems: 'center',
			}}
		>
			<div
				style={{
					width: '100%',
					maxWidth: 400,
					borderRadius: 10,
					boxShadow: '2px 2px 5px 0px rgba(0,0,0,0.25), -1px -1px 3.5px 0px rgba(0,0,0,0.25)',
				}}
			>
				<Typography.Title
					level={1}
					style={{
						fontSize: '1.8em',
						textAlign: 'center',
						borderBottom: 'thin solid gray',
						padding: 16,
						textTransform: 'uppercase',
					}}
				>
					Change Password
				</Typography.Title>

				<Form layout="vertical" style={{ padding: '24px 16px' }} onFinish={onFinish}>
					<Form.Item
						name="newPassword"
						label="Enter New Password"
						rules={[{ required: true, message: 'Please provide new password' }]}
					>
						<Input.Password disabled={isLoading} />
					</Form.Item>
					<Form.Item
						name="newPasswordConfirm"
						label="Confirm New Password"
						rules={[
							{ required: true, message: 'Please confirm new password' },
							comparePasswords,
						]}
					>
						<Input.Password disabled={isLoading} />
					</Form.Item>

					<Button
						block
						type="primary"
						htmlType="submit"
						loading={isLoading}
						style={{ marginTop: 8 }}
					>
						{isLoading ? 'Changing Password...' : 'Change Password'}
					</Button>
				</Form>
			</div>
		</main>
	);
};

export default ChangePassword;
