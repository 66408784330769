import {
	getAuth,
	GoogleAuthProvider,
	signInWithPopup,
	signOut,
	signInWithEmailAndPassword,
} from 'firebase/auth';
import firebaseApp from 'config/firebase';
import { message } from 'antd';

const provider = new GoogleAuthProvider();

export const auth = getAuth(firebaseApp);

export const logInWithGoogle = () =>
	signInWithPopup(auth, provider)
		.then((userCred) => ({
			user: {
				uid: userCred.uid,
				displayName: userCred.displayName,
				email: userCred.email,
				phoneNumber: userCred.phoneNumber,
				photoURL: userCred.photoURL,
			},
			token: userCred._tokenResponse.idToken,
		}))
		.catch((err) => console.error(err));

export const logInWithCreds = ({ email, password }) =>
	signInWithEmailAndPassword(auth, email, password)
		.then((userCred) => ({
			user: {
				uid: userCred.uid,
				displayName: userCred.displayName,
				email: userCred.email,
				phoneNumber: userCred.phoneNumber,
				photoURL: userCred.photoURL,
			},
			token: userCred._tokenResponse.idToken,
		}))
		.catch((err) => {
			console.error({ err });
			message.error(err.code);
		});

export const logOut = () =>
	signOut(auth)
		.then((res) => res)
		.catch((err) => console.error(err));
