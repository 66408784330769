import Icon from '@ant-design/icons';

const component = () => (
	<svg width="22" height="17" viewBox="0 0 22 17" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M0.571289 5.67688L9.70329 10.4263C9.79489 10.4739 9.89662 10.4988 9.99986 10.4988C10.1031 10.4988 10.2048 10.4739 10.2964 10.4263L16.8004 7.04488C17.2187 7.20002 17.6704 7.28488 18.1427 7.28488C18.5936 7.28488 19.0273 7.20774 19.4284 7.06545V13.9277C19.4285 14.6391 19.1564 15.3236 18.6679 15.8408C18.1794 16.358 17.5115 16.6687 16.8013 16.7092L16.6427 16.7134H3.357C2.64546 16.7135 1.96086 16.4413 1.44366 15.9526C0.926461 15.4639 0.615859 14.7959 0.575575 14.0855L0.571289 13.9277V5.67688ZM3.357 1.28488H14.9353C14.5106 1.91876 14.2845 2.66476 14.2856 3.42773C14.2856 4.54716 14.7621 5.55516 15.5241 6.25973L9.99986 9.13116L0.571289 4.22916V4.07059C0.571242 3.35905 0.843478 2.67445 1.33215 2.15725C1.82082 1.64005 2.48888 1.32945 3.19929 1.28916L3.357 1.28488ZM18.1427 0.427734C18.9384 0.427734 19.7014 0.743805 20.264 1.30641C20.8266 1.86902 21.1427 2.63209 21.1427 3.42773C21.1427 4.22338 20.8266 4.98645 20.264 5.54906C19.7014 6.11166 18.9384 6.42773 18.1427 6.42773C17.3471 6.42773 16.584 6.11166 16.0214 5.54906C15.4588 4.98645 15.1427 4.22338 15.1427 3.42773C15.1427 2.63209 15.4588 1.86902 16.0214 1.30641C16.584 0.743805 17.3471 0.427734 18.1427 0.427734Z"
			fill="#042954"
		/>
	</svg>
);

const CustomInboxIcon = (props) => <Icon {...{ props, component }} />;

export default CustomInboxIcon;
