import React, { useState } from 'react';
import { Input, Button, List } from 'antd';
import locicon from '../LeafletMap/locicon.png';
import './search.scss';

const NOMINATIM_BASE_URL = 'https://nominatim.openstreetmap.org/search?';

const Search = (props) => {
	const { setSelectPosition, setPosition } = props;
	const [searchText, setSearchText] = useState('');
	const [listPlace, setListPlace] = useState([]);
	const [display, setDisplay] = useState(false);

	return (
		<div>
			<div
				className="search-bar"
				style={{ display: 'flex', alignItems: 'center', marginTop: '10px', width: '100%' }}
			>
				<div className="search-input">
					<Input
						style={{ borderRadius: '32px', width: '100%' }}
						placeholder="Search Location"
						value={searchText}
						onChange={(e) => {
							setSearchText(e.target.value);
							if (e.target.value.length === 0) {
								setDisplay(false);
							} else {
								setDisplay(true);
								const params = {
									q: searchText,
									format: 'json',
									addressdetails: 1,
									polygon_geojson: 0,
								};
								const queryString = new URLSearchParams(params).toString();
								const requestOptions = {
									method: 'GET',
									redirect: 'follow',
								};
								fetch(`${NOMINATIM_BASE_URL}${queryString}`, requestOptions)
									.then((response) => response.text())
									.then((result) => {
										setListPlace(JSON.parse(result));
									})
									.catch((err) => console.log(err));
							}
						}}
					></Input>
				</div>
				<div>
					<Button
						type="primary"
						className="search-button"
						onClick={() => {
							setDisplay(true);
							const params = {
								q: searchText,
								format: 'json',
								addressdetails: 1,
								polygon_geojson: 0,
							};
							const queryString = new URLSearchParams(params).toString();
							const requestOptions = {
								method: 'GET',
								redirect: 'follow',
							};
							fetch(`${NOMINATIM_BASE_URL}${queryString}`, requestOptions)
								.then((response) => response.text())
								.then((result) => {
									setListPlace(JSON.parse(result));
								})
								.catch((err) => console.log(err));
						}}
					>
						Search
					</Button>
				</div>
			</div>
			{display && (
				<div className="list" style={{ width: '60%' }}>
					{display === true && (
						// <List dense component="div" role="list">
						// 	{listPlace.slice(0, 4).map((item, index) => {
						// 		return (
						// 			<div
						// 				key={item?.osm_id}
						// 				style={{
						// 					padding: '5px',
						// 					backgroundColor: 'white',
						// 					width: '100%',
						// 					borderTopLeftRadius: `${index}` === 0 ? '10px' : '0px',
						// 					borderTopRightRadius: `${index}` === 0 ? '10px' : '0px',
						// 					borderBottomLeftRadius: `${index}` === 3 ? '10px' : '0px',
						// 					borderBottomRightRadius: `${index}` === 3 ? '10px' : '0px',
						// 				}}
						// 			>
						// 				<div style={{ display: 'flex', alignItems: 'center' }}>
						// 					<img
						// 						src={locicon}
						// 						alt="locicon"
						// 						style={{ width: '30px', height: '25px' }}
						// 					/>
						// 					<ListItem
						// 						button
						// 						onClick={() => {
						// 							setDisplay(false);
						// 							setSelectPosition(item);
						// 							setPosition({
						// 								lat: Number(item?.lat),
						// 								lng: Number(item?.lon),
						// 							});
						// 						}}
						// 					>
						// 						<ListItemText
						// 							className="list-text"
						// 							primary={item?.display_name}
						// 							style={{ color: 'grey' }}
						// 						/>
						// 					</ListItem>
						// 				</div>
						// 			</div>
						// 		);
						// 	})}

						// 	<ListItem />
						// </List>
						<List
							style={{ background: 'white', marginTop: '5px', borderRadius: '10px' }}
							bordered
							dataSource={listPlace.slice(0, 4)}
							renderItem={(item) => (
								<List.Item
									key={item?.osm_id}
									style={{ cursor: 'pointer' }}
									onClick={() => {
										setDisplay(false);
										setSelectPosition(item);
										setPosition({
											lat: Number(item?.lat),
											lng: Number(item?.lon),
										});
									}}
								>
									<div style={{ display: 'flex', alignItems: 'center' }}>
										<img
											src={locicon}
											alt="locicon"
											style={{ width: '30px', height: '25px', marginRight: '5px' }}
										/>
										<h4 style={{ color: 'grey' }}>{item.display_name}</h4>
									</div>
								</List.Item>
							)}
						/>
					)}
				</div>
			)}
		</div>
	);
};

export default Search;
