import { MapContainer, TileLayer, Marker, useMap, useMapEvents, Popup } from 'react-leaflet';

import L from 'leaflet';
import { useEffect, useState } from 'react';
import 'leaflet/dist/leaflet.css';
import markerSvg from './marker.svg';

const icon = new L.Icon({
	iconUrl: markerSvg,
	iconRetinaUrl: markerSvg,

	iconSize: new L.Point(60, 75),
});

function ResetCenterView(props) {
	const { selectPosition } = props;
	const map = useMap();

	useEffect(() => {
		if (selectPosition && !isNaN(selectPosition?.lat)) {
			map.setView(L.latLng(selectPosition?.lat, selectPosition?.lng), map.getZoom(), {
				animate: true,
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectPosition]);
	return null;
}
// function PositionMarker(props) {
//   const {locationSelection}=props;

// 	// const map = useMapEvents({
// 	// 	click(e) {
// 	// 		if (setPosition) {
// 	// 			setPosition({ ...e.latlng });
// 	// 			map.flyTo(e.latlng, map.getZoom());
// 	// 		}
// 	// 	},
// 	// });

// 	return <Marker {...{ locationSelection, icon }}></Marker>;
// }
// const useGenerateState = props => {
// 	const [marker,setMarker]=useState(false)

//     return {
//         state: {
//             marker,
//             //etc
//         },
//         setters: {
//             setMarker,
//             //etc
//         }
//     }
// }

function LocationMarker(props) {
	const { setMarker, marker2, setMarker2, setClickablePosition } = props;

	const [position, setPosition] = useState(null);
	const map = useMapEvents({
		click(e) {
			if (setPosition) {
				setPosition({ ...e.latlng });
				setClickablePosition({ ...e.latlng });

				setMarker(true);
				setMarker2(true);
				map.flyTo(e.latlng, map.getZoom());
			}
		},
	});

	return position === null
		? null
		: marker2 && (
				<Marker position={position} icon={icon}>
					<Popup>
						{position?.lat.toFixed(3)},{position?.lng.toFixed(3)}
					</Popup>
				</Marker>
		  );
}

const Map = (props) => {
	const { selectPosition, clickablePosition, setClickablePosition } = props;
	const locationSelection = [selectPosition?.lat, selectPosition?.lng];
	const [marker, setMarker] = useState(false);
	const [marker2, setMarker2] = useState(true);

	var position;
	if (
		selectPosition?.lat === undefined ||
		selectPosition?.lng === undefined ||
		isNaN(selectPosition?.lat) ||
		isNaN(selectPosition?.lng)
	) {
		position = [20.5937, 78.9629];
	} else {
		position = locationSelection;
	}

	useEffect(() => {
		if (
			selectPosition?.lat === undefined ||
			selectPosition?.lng === undefined ||
			isNaN(selectPosition?.lat) ||
			isNaN(selectPosition?.lng)
		) {
			// eslint-disable-next-line react-hooks/exhaustive-deps
			position = [20.5937, 78.9629];
		} else {
			position = locationSelection;
			setMarker(false);
			setMarker2(false);
		}
	}, [selectPosition]);

	return (
		<MapContainer zoom={15} center={position} id="map" style={{ height: '100%', width: '100%' }}>
			<TileLayer
				attribution='&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>, &copy; <a href="https://carto.com/attributions">CARTO</a>'
				url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
			/>

			{!marker && (
				<Marker position={position} icon={icon}>
					<Popup>
						{position[0]},{position[1]}
					</Popup>
				</Marker>
			)}

			<LocationMarker
				marker={marker}
				setMarker={setMarker}
				marker2={marker2}
				setMarker2={setMarker2}
				clickablePosition={clickablePosition}
				setClickablePosition={setClickablePosition}
			/>

			<ResetCenterView selectPosition={selectPosition} />
		</MapContainer>
	);
};

export default Map;
