import * as React from 'react';
import { Outlet } from 'react-router-dom';
import LayoutWrapper from './main';

export default function Layout() {
	return (
		<LayoutWrapper>
			<Outlet />
		</LayoutWrapper>
	);
}
