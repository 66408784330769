import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Layout } from 'antd';

import Header from './Header';
import Sidebar from './Sidebar';
import './assets/styles.scss';

import useWindowDimensions from 'hooks/use-window-dimensions';

export default function Wrapper({ children }) {
	
	const user = useSelector((state) => state.auth.user);
	const [collapsed, setCollapse] = useState(false);
	const { width } = useWindowDimensions();

	// collapse sidebar on smaller screens
	useEffect(() => {
		if (width <= 480) setCollapse(true);
	}, [width]);

	const toggle = () => setCollapse((prev) => !prev);

	return (
		<Layout>
			<Sidebar {...{ user, collapsed}} />
			<Layout className="site-layout">
				<Header {...{ user, collapsed, toggle, width }} />

				<Layout.Content
					
					style={{
						margin: 16,
						padding: 8,
						minHeight: 280,
					}}
				>
					{children}
				</Layout.Content>
			</Layout>
		</Layout>
	);
}
