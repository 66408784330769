import Icon from '@ant-design/icons';

const component = () => (
	<svg width="21" height="18" viewBox="0 0 21 18" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M8 0C6.93913 0 5.92172 0.421427 5.17157 1.17157C4.42143 1.92172 4 2.93913 4 4C4 5.06087 4.42143 6.07828 5.17157 6.82843C5.92172 7.57857 6.93913 8 8 8C9.06087 8 10.0783 7.57857 10.8284 6.82843C11.5786 6.07828 12 5.06087 12 4C12 2.93913 11.5786 1.92172 10.8284 1.17157C10.0783 0.421427 9.06087 0 8 0ZM15 8C14.9389 7.99886 14.8793 8.01929 14.8318 8.05769C14.7842 8.09609 14.7517 8.15002 14.74 8.21L14.55 9.53C14.25 9.66 13.96 9.82 13.7 10L12.46 9.5C12.35 9.5 12.22 9.5 12.15 9.63L11.15 11.36C11.09 11.47 11.11 11.6 11.21 11.68L12.27 12.5C12.2301 12.8321 12.2301 13.1679 12.27 13.5L11.21 14.32C11.1651 14.3585 11.1346 14.4113 11.1237 14.4694C11.1128 14.5276 11.1221 14.5878 11.15 14.64L12.15 16.37C12.21 16.5 12.34 16.5 12.46 16.5L13.7 16C13.96 16.18 14.24 16.35 14.55 16.47L14.74 17.79C14.76 17.91 14.86 18 15 18H17C17.11 18 17.22 17.91 17.24 17.79L17.43 16.47C17.73 16.34 18 16.18 18.27 16L19.5 16.5C19.63 16.5 19.76 16.5 19.83 16.37L20.83 14.64C20.8579 14.5878 20.8672 14.5276 20.8563 14.4694C20.8454 14.4113 20.8149 14.3585 20.77 14.32L19.7 13.5C19.72 13.33 19.74 13.17 19.74 13C19.74 12.83 19.73 12.67 19.7 12.5L20.76 11.68C20.8049 11.6415 20.8354 11.5887 20.8463 11.5306C20.8572 11.4724 20.8479 11.4122 20.82 11.36L19.82 9.63C19.76 9.5 19.63 9.5 19.5 9.5L18.27 10C18 9.82 17.73 9.65 17.42 9.53L17.23 8.21C17.2237 8.15304 17.1969 8.10032 17.1546 8.06167C17.1122 8.02303 17.0573 8.00111 17 8H15ZM8 10C3.58 10 0 11.79 0 14V16H9.68C9.2337 15.0628 9.00142 14.038 9 13C9.00203 11.9953 9.22031 11.0028 9.64 10.09C9.11 10.03 8.56 10 8 10ZM16 11.5C16.83 11.5 17.5 12.17 17.5 13C17.5 13.83 16.83 14.5 16 14.5C15.16 14.5 14.5 13.83 14.5 13C14.5 12.17 15.17 11.5 16 11.5Z"
			fill="#042954"
		/>
	</svg>
);
const CustomAccSettingsIcon = (props) => <Icon {...{ props, component }} />;

export default CustomAccSettingsIcon;
