import { useState, useEffect } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { Layout, Menu, Avatar, Image, Typography } from 'antd';
import { HomeOutlined, SettingOutlined, TableOutlined } from '@ant-design/icons';
import profImg from '../assets/profile.jpg';
import dash from '../assets/dash.png';
import dashg from '../assets/dashg.png';
import property from '../assets/Property.png';
import propertyb from '../assets/Propertyb.png';

import User from '../assets/user.png';
import settings from '../assets/settings.png';

export default function Sidebar({ user, collapsed }) {
const { id } = useParams();

	const location = useLocation();
	const [selectedKey, setSelectedKey] = useState([]);

	useEffect(() => {
		const navHelper = {
			'/': '1',
			'/poxes': '2',
			[`/poxes/poxverif/${id}`]: '2',
			'/poxes/owned': '3',
			'/users': '5',
			[`/users/${id}`]: '5',
			'/settings': '4',
			'/sales': '1',
		};
		const { pathname } = location;
		setSelectedKey([navHelper[pathname]]);
	}, [location]);

	return (
		<Layout.Sider
			trigger={null}
			collapsible
			collapsed={collapsed}
			style={{ background: user?.isAdmin ? 'white' : '' }}
			width="240"
		>
			<a href={process.env.REACT_APP_MAP_URL} target="_blank" rel="noreferrer">
				<div className="logo" style={{ cursor: 'pointer' }} />
			</a>

			<div className="user-avatar">
				<Avatar
					size={collapsed ? 48 : 80}
					src={
						<Image
							src={user.photoURL || profImg}
							preview={false}
							style={{ width: '100%' }}
							referrerPolicy="no-referrer"
						/>
					}
				/>
				{!collapsed && (
					<Typography.Title
						level={5}
						style={{
							margin: 0,
							color: user?.isAdmin ? 'black' : '#FFF',
							textAlign: 'center',
							fontSize: '20px',
						}}
					>
						{user.displayName}
					</Typography.Title>
				)}
			</div>
			{!user?.isAdmin && (
				<Menu
					theme="dark"
					mode="inline"
					selectedKeys={selectedKey}
					items={[
						{ key: '1', icon: <HomeOutlined />, label: <Link to="/">Dashboard</Link> },
						{ key: '2', icon: <TableOutlined />, label: <Link to="/poxes">Poxes</Link> },
						{
							key: '3',
							icon: <TableOutlined />,
							label: <Link to="/poxes/owned">My Poxes</Link>,
						},
						{
							key: '4',
							icon: <SettingOutlined />,
							label: <Link to="/settings">Settings</Link>,
						},
					]}
				/>
			)}

			{user?.isAdmin && (
				<Menu
					mode="inline"
					selectedKeys={selectedKey}
					items={[
						{
							key: '1',
							style: {
								height: '60px',
								background:
									parseInt(selectedKey[0]) === 1 ? 'rgba(23, 24, 92, 0.1)' : 'white',
							},
							icon: (
								<img src={parseInt(selectedKey[0]) === 1 ? dash : dashg} alt="property" />
							),
							label: (
								<Link
									to="/"
									style={{
										color: parseInt(selectedKey[0]) === 1 ? '#17185C' : '#808080',
										fontSize: '25px',
									}}
								>
									Dashboard
								</Link>
							),
						},
						{
							key: '2',
							style: {
								height: '60px',
								background:
									parseInt(selectedKey[0]) === 2 ? 'rgba(23, 24, 92, 0.1)' : 'white',
							},

							icon: (
								<img
									src={parseInt(selectedKey[0]) === 2 ? propertyb : property}
									alt="property"
								/>
							),
							label: (
								<Link
									to="/poxes"
									style={{
										color: parseInt(selectedKey[0]) === 2 ? '#17185C' : '#808080',
										fontSize: '25px',
									}}
								>
									Poxes
								</Link>
							),
						},

						{
							key: '5',
							style: {
								height: '60px',
								background:
									parseInt(selectedKey[0]) === 5 ? 'rgba(23, 24, 92, 0.1)' : 'white',
							},

							icon: <img src={User} alt="user" />,
							label: (
								<Link
									to="/users"
									style={{
										color: parseInt(selectedKey[0]) === 5 ? '#17185C' : '#808080',
										fontSize: '25px',
									}}
								>
									Users
								</Link>
							),
						},
						{
							key: '4',
							style: {
								height: '60px',
								background:
									parseInt(selectedKey[0]) === 4 ? 'rgba(23, 24, 92, 0.1)' : 'white',
							},

							icon: <img src={settings} alt="settings" />,
							label: (
								<Link
									to="/settings"
									style={{
										color: parseInt(selectedKey[0]) === 4 ? '#17185C' : '#808080',
										fontSize: '25px',
									}}
								>
									Settings
								</Link>
							),
						},
					]}
				/>
			)}
		</Layout.Sider>
	);
}
