import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'apis/axios';
import { getUser } from 'redux/features/auth';
import HomePage2 from '../HomePage2';
import { useSelector } from 'react-redux';
import { PageHeader, Spin } from 'antd';

export default function Home() {
	const user = useSelector(getUser);
	const [isLoading, setIsLoading] = useState(true);
	const [data, setData] = useState(null);

	useEffect(() => {
		const fetchData = async () => {
			setIsLoading(true);
			try {
				const { data } = await axios.get(`/pox?publishedBy=${user.uid}`);
				setData([
					{ label: 'All Poxes', url: 'poxes', amount: data.poxes.length },
					{
						label: 'Active Poxes',
						url: 'poxes',
						amount: data.poxes.filter((e) => e.status === 'active').length,
					},
					{
						label: 'Deactivated Poxes',
						url: 'poxes',
						amount: data.poxes.filter((e) => e.status === 'deactivated').length,
					},
					{
						label: 'My Pox',
						url: 'poxes/owned',
						amount: data.poxes.filter((e) => e.ownership).length,
					},
				]);
			} catch (error) {
				console.error(error);
			} finally {
				setIsLoading(false);
			}
		};
		fetchData();

		return () => {
			setData([]);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (user?.isAdmin) {
		return <HomePage2 />;
	}

	return (
		<div>
			<PageHeader className="site-page-header" title={'Poxes Statistics'} />

			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'space-evenly',
					gap: 30,
					flexWrap: 'wrap',
				}}
			>
				{data &&
					data.map(({ label, url, amount }, idx) => (
						<Link
							to={url}
							key={idx}
							style={{
								width: 145,
								height: 145,
								border: 'thin solid',
								borderRadius: '50%',
								textAlign: 'center',
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'center',
								justifyContent: 'center',
								padding: 16,
							}}
						>
							<h4
								style={{
									fontSize: '1.25em',
									whiteSpace: 'wrap',
									lineHeight: 1,
									marginBottom: 5,
								}}
							>
								{label}
							</h4>
							<span style={{ fontSize: '2em', fontWeight: '700' }}>{amount}</span>
						</Link>
					))}

				{isLoading && <Spin size="large" style={{ marginTop: 16 }} />}
			</div>
		</div>
	);
}
