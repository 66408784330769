import { useState } from 'react';

// Styling components
import { Form, Input, Button } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';

// reducer action
import logo from './assets/logo.png';
import { logInWithCreds } from 'utils/firebase/auth';

export default function AdminLogin() {
	const [loading, setLoading] = useState(false);

	const onFinish = async (values) => {
		setLoading(true);
		try {
			await logInWithCreds(values);
		} catch (error) {
			console.error(error);
		} finally {
			setLoading(false);
		}
	};
	return (
		<div style={{ padding: '0 2%' }}>
			<header style={{ display: 'flex', justifyContent: 'center' }}>
				<img src={logo} alt="logo" style={{ maxWidth: '100%' }} />
			</header>

			<Form
				name="login_form"
				wrapperCol={{ span: 24 }}
				onFinish={onFinish}
				style={{ maxWidth: 480, margin: 'auto' }}
			>
				<Form.Item
					name="email"
					rules={[{ required: true, message: 'Please enter your Admin Email!' }]}
				>
					<Input
						size="large"
						prefix={<UserOutlined className="site-form-item-icon" />}
						placeholder="Email"
						disabled={loading}
					/>
				</Form.Item>

				<Form.Item
					name="password"
					rules={[{ required: true, message: 'Please enter your Admin Password!' }]}
				>
					<Input
						size="large"
						prefix={<LockOutlined className="site-form-item-icon" />}
						type="password"
						placeholder="Password"
						disabled={loading}
					/>
				</Form.Item>

				<Form.Item>
					<Button type="primary" htmlType="submit" style={{ width: '100%' }}>
						{loading ? 'Signing in...' : 'Sign in'}
					</Button>
				</Form.Item>
			</Form>
		</div>
	);
}
