import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from 'redux/store';
import MyApp from 'app';
import './styles/index.scss';

createRoot(document.getElementById('root')).render(
	<BrowserRouter>
		<Provider store={store}>
			<MyApp />
		</Provider>
	</BrowserRouter>
);
