import { useState, useEffect, Fragment } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
	Button,
	Row,
	Col,
	Divider,
	Form,
	Input,
	InputNumber,
	Select,
	Radio,
	Space,
	PageHeader,
	Upload,
	message,
} from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import Map from 'components/LeafletMap';
import Search from '../../../components/LeafletMap/Search';
import useAxiosPrivate from 'hooks/use-axios-private';
import { customRequest, onRemove } from 'utils/firebase/storage';
import { PoxLoader } from 'components';

export default function EditPox() {
	const { id } = useParams();
	const navigate = useNavigate();
	const axiosPrivate = useAxiosPrivate();
	const [isFetching, setIsFetching] = useState(false);
	const [isEditing, setIsEditing] = useState(false);
	const [data, setData] = useState(null);
	const [poxType, setPoxType] = useState(null);
	const [payType, setPayType] = useState(null);
	const [selectPosition, setSelectPosition] = useState({
		lat: 20.5937,
		lon: 78.9629,
	});
	const [position, setPosition] = useState({
		lat: Number(selectPosition?.lat),
		lng: Number(selectPosition?.lon),
	});

	useEffect(() => {
		const fetchData = async () => {
			setIsFetching(true);
			try {
				const res = await axiosPrivate.get(`/pox/${id}`);
				setData(res.data.pox);
				setPoxType(res.data.pox.type);
				setPayType(res.data.pox.pay_type);
				setPosition(res.data.pox.position);
			} catch (error) {
				message.error(error?.response?.data?.message || error.message);
			} finally {
				setIsFetching(false);
			}
		};
		fetchData();

		return () => {
			setData(null);
			setPoxType(null);
			setPayType(null);
			setPosition(null);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const normFile = (e) => {
		const list = e?.fileList.map((f) => {
			if (f.response) {
				return {
					uid: f.uid,
					name: f.name,
					size: f.size,
					status: f.status,
					pathname: f.response.name,
					url: f.response.url,
				};
			}
			return f;
		});
		return list;
	};

	const handleCancel = () => {
		const res = window.confirm('Are you sure you want to leave this page? Changes would be lost');
		if (res) navigate(-1);
	};

	const handleOnFinish = async (values) => {
		let stillUploading = false;
		let failedUpload = false;

		values.images.forEach((e) => {
			if (e.status === 'uploading') stillUploading = true;
			if (e.status === 'error') failedUpload = true;
		});

		if (stillUploading)
			return message.error(
				'Please wait for pending uploads to complete or cancel and retry if taking too long'
			);

		if (failedUpload) return message.error('Please remove failed image upload and try again');

		const bodyData = { ...values, id: data.id, position };
		setIsEditing(true);

		try {
			const res = await axiosPrivate.put(`/pox/${id}`, bodyData);
			message.success(res.data.message);
			navigate(`/poxes/${id}`, { replace: true });
		} catch (error) {
			message.error(error?.response?.data.message || error.message);
		} finally {
			setIsEditing(false);
		}
	};

	return (
		<Fragment>
			<PageHeader className="site-page-header" title={data?.name} />

			{isFetching && <PoxLoader title="Fetching Pox Info..." />}

			{data && (
				<Form name="new_pox" onFinish={handleOnFinish} initialValues={data}>
					<Row className="new-pox" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
						<Col span={12} className="info-section">
							<div style={{ backgroundColor: '#f2f3f8', padding: 16, marginBottom: 16 }}>
								<Form.Item
									name="images"
									valuePropName="fileList"
									getValueFromEvent={normFile}
									rules={[
										{
											required: true,
											message: 'Please provide an image for the property',
										},
										() => ({
											validator(_, value) {
												if (!value || value?.length > 2 || value.fileList?.length > 2) {
													return Promise.resolve();
												}
												return Promise.reject(
													new Error('Upload a minimum of 3 images')
												);
											},
										}),
									]}
								>
									<Upload.Dragger
										name="pox-img"
										defaultFileList={data.images}
										customRequest={customRequest}
										onRemove={onRemove}
										multiple
									>
										<p className="ant-upload-drag-icon">
											<InboxOutlined />
										</p>
										<p className="ant-upload-text">
											Click or drag file to this area to upload
										</p>
										<p className="ant-upload-hint">Upload minimum of 3 images</p>
									</Upload.Dragger>
								</Form.Item>

								<Form.Item
									name="name"
									label="Enter Property Name"
									rules={[
										{
											required: true,
											message: 'Please enter Property name',
										},
									]}
									style={{ marginTop: 16 }}
								>
									<Input placeholder="Property name" />
								</Form.Item>

								<Form.Item
									name="type"
									label="Select Property Type"
									rules={[
										{
											required: true,
											message: 'Please pick a Property type',
										},
									]}
									style={{ marginBottom: 0 }}
								>
									<Radio.Group
										onChange={(e) => {
											setPoxType(e.target.value);
											setPayType(null);
										}}
									>
										<Radio value="Flat">FLAT</Radio>
										<Radio value="Plot">PLOT</Radio>
										<Radio value="Estab">ESTAB</Radio>
									</Radio.Group>
								</Form.Item>
							</div>

							{poxType !== null && (
								<div style={{ backgroundColor: '#f2f3f8', padding: 16, marginBottom: 16 }}>
									{poxType === 'Flat' && (
										<Fragment>
											<Form.Item
												name="room_config"
												label="Enter number of rooms"
												rules={[
													{
														required: true,
														message: 'Please enter number of rooms',
													},
												]}
											>
												<InputNumber min={0} />
											</Form.Item>
											<Form.Item
												name="bath"
												label="Bath"
												rules={[
													{
														required: true,
														message: 'Please select number of bath',
													},
												]}
											>
												<InputNumber />
											</Form.Item>
											<Form.Item
												name="floor"
												label="Select Floor"
												rules={[
													{
														required: true,
														message: 'Please select floor',
													},
												]}
											>
												<Radio.Group>
													<Radio value="Ground">Ground</Radio>
													<Radio value="First">First</Radio>
													<Radio value="Middle">Middle</Radio>
													<Radio value="Top">Top</Radio>
												</Radio.Group>
											</Form.Item>
											<Form.Item
												name="facing"
												label="Select Entrance face direction"
												rules={[
													{
														required: true,
														message: 'Please select direction',
													},
												]}
											>
												<Select>
													<Select.Option value="North">North</Select.Option>
													<Select.Option value="East">East</Select.Option>
													<Select.Option value="West">West</Select.Option>
													<Select.Option value="South">South</Select.Option>
													<Select.Option value="North-East">North-East</Select.Option>
													<Select.Option value="North-West">North-West</Select.Option>
													<Select.Option value="South-East">South-East</Select.Option>
													<Select.Option value="South-West">South-West</Select.Option>
												</Select>
											</Form.Item>
										</Fragment>
									)}
									{poxType === 'Plot' && (
										<Form.Item
											name="plot_config"
											label="Select Plot Type"
											rules={[
												{
													required: true,
													message: 'Please select a plot type',
												},
											]}
										>
											<Radio.Group>
												<Radio value="Residential">Residential</Radio>
												<Radio value="Commercial">Commercial</Radio>
												<Radio value="Agricultural">Agricultural</Radio>
											</Radio.Group>
										</Form.Item>
									)}
									<Form.Item
										name="area"
										label={
											poxType === 'Estab'
												? 'Floor Area'
												: poxType === 'Flat'
												? 'Built up Area'
												: 'Plot Area'
										}
										rules={[
											{
												required: true,
												message:
													poxType === 'Estab'
														? 'Please enter floor area'
														: poxType === 'Flat'
														? 'Please enter built up Area'
														: 'Please enter plot area',
											},
										]}
									>
										<Input placeholder="1000" suffix="SQR FT" />
									</Form.Item>
									<Form.Item
										name="pay_type"
										label="Select"
										rules={[
											{
												required: true,
												message: 'Please select a payment type',
											},
										]}
										style={{ marginBottom: 0 }}
									>
										<Radio.Group onChange={(e) => setPayType(e.target.value)}>
											{poxType !== 'Plot' && <Radio value="Rent">RENT</Radio>}
											<Radio value="Sale">SALE</Radio>
											{poxType === 'Plot' && <Radio value="Lease">LEASE</Radio>}
										</Radio.Group>
									</Form.Item>
								</div>
							)}

							{poxType !== null && payType !== null && (
								<div
									style={{
										backgroundColor: '#f2f3f8',
										padding: 16,
										marginBottom: 16,
									}}
								>
									{payType === 'Sale' && (
										<Form.Item
											name="sale_cost"
											label="Enter Sale Cost"
											rules={[
												{
													required: true,
													message: 'Please enter selling cost',
												},
											]}
											style={poxType !== 'Estab' && { marginBottom: 0 }}
										>
											<Input placeholder="1000" />
										</Form.Item>
									)}
									{payType === 'Rent' && (
										<Fragment>
											<Form.Item
												name="rent_cost"
												label="Enter Rent"
												rules={[
													{
														required: true,
														message: 'Please enter rent amount',
													},
												]}
											>
												<Input placeholder="1000" />
											</Form.Item>
											<Form.Item
												name="deposit_cost"
												label="Enter Deposit"
												rules={[
													{
														required: true,
														message: 'Please enter deposit amount',
													},
												]}
												style={poxType !== 'Estab' && { marginBottom: 0 }}
											>
												<Input placeholder="1000" />
											</Form.Item>
										</Fragment>
									)}
									{poxType === 'Estab' && payType !== null && (
										<Form.Item
											name="turnover_cost"
											label="Enter Turnover"
											rules={[
												{
													required: true,
													message: 'Please enter turnover',
												},
											]}
											style={{ marginBottom: 0 }}
										>
											<Input placeholder="1000" />
										</Form.Item>
									)}
									{poxType === 'Plot' && payType === 'Lease' && (
										<Fragment>
											<Form.Item
												name="lease_cost"
												label="Enter Lease Cost"
												rules={[
													{
														required: true,
														message: 'Please enter lease cost',
													},
												]}
											>
												<Input placeholder="1000" />
											</Form.Item>
											<Form.Item
												name="lease_period"
												label="Enter Lease Duration (days)"
												rules={[
													{
														required: true,
														message: 'Please enter lease duration',
													},
												]}
												style={{ marginBottom: 0 }}
											>
												<InputNumber min={0} />
											</Form.Item>
										</Fragment>
									)}
								</div>
							)}

							<div style={{ backgroundColor: '#f2f3f8', padding: 16 }}>
								<Form.Item
									name="contact_name"
									label="Enter Contact Name"
									rules={[
										{
											required: true,
											message: 'Please enter Contact name',
										},
									]}
								>
									<Input />
								</Form.Item>
								<Form.Item
									name="contact_number"
									label="Enter Contact Number"
									rules={[
										{
											required: true,
											message: 'Please enter Contact number',
										},
									]}
									style={{ marginBottom: 0 }}
								>
									<Input />
								</Form.Item>
							</div>
						</Col>

						<Col span={12} className="map-section">
							<Divider orientation="left">Pick Location</Divider>

							{/* map select area */}
							<div style={{ display: 'flex', flexDirection: 'column' }}>
								<div
									style={{
										width: '90%',
										marginBottom: 16,
										position: 'relative',
										left: '50px',
										zIndex: 2,
									}}
								>
									<Search
										selectPosition={selectPosition}
										setSelectPosition={setSelectPosition}
										setPosition={setPosition}
									/>
								</div>
								<div
									style={{
										width: '96%',
										height: '350px',
										position: 'absolute',
										zIndex: 1,
									}}
								>
									<Map
										selectPosition={position}
										clickablePosition={position}
										setClickablePosition={setPosition}
									/>
								</div>
							</div>
							<div style={{ position: 'absolute', top: '430px', width: '96%' }}>
								<Form.Item
									name="address"
									label="Enter address"
									rules={[{ required: true, message: 'Please provide addresss' }]}
								>
									<Input />
								</Form.Item>

								<Form.Item name="description">
									<Input.TextArea
										placeholder="Description"
										showCount
										maxLength={100}
										autoSize={{ minRows: 3, maxRows: 5 }}
										style={{ marginBottom: 32 }}
									/>
								</Form.Item>

								<div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
									<Space size="large">
										<Button
											danger
											shape="round"
											htmlType="button"
											onClick={handleCancel}
											style={{ width: 125 }}
										>
											Cancel
										</Button>
										<Button
											type="primary"
											shape="round"
											htmlType="submit"
											style={{ width: 125 }}
										>
											Submit
										</Button>
									</Space>
								</div>
							</div>
						</Col>
					</Row>
				</Form>
			)}

			{isEditing && <PoxLoader title="Please wait while your Pox is being updated..." />}
		</Fragment>
	);
}
