import { useState, useEffect, Fragment } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import axios from 'apis/axios';
import { Table, Space, Button, PageHeader, message } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import useAxiosPrivate from 'hooks/use-axios-private';
import { getUser } from 'redux/features/auth';
import PoxAdmin from '../PoxAdmin';
import { onRemove } from 'utils/firebase/storage';

export default function Poxes() {
	const navigate = useNavigate();
	const location = useLocation();
	const user = useSelector(getUser);
	const [isLoading, setIsLoading] = useState(true);
	const [data, setData] = useState([]);
	const axiosPrivate = useAxiosPrivate();

	const fetchData = async (owned = '') => {
		setIsLoading(true);
		try {
			const res =
				user.email === 'admin@ucpox.com'
					? await axios.get(`/pox`)
					: await axios.get(`/pox?publishedBy=${user.uid}${owned}`);
			setData(
				res.data.poxes.map((e) => ({
					key: e._id,
					id: e.id,
					name: e.name,
					type: e.type,
					date: e.createdAt,
					status: e.status,
					userId: e.publishedBy,
					images: e.images,
				}))
			);
		} catch (error) {
			console.error(error);
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		fetchData(location.pathname === '/poxes/owned' ? '&ownership=true' : '');

		return () => {
			setData([]);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location]);

	// delete location
	const deletePox = async (id) => {
		try {
			data.find((e) => e.key === id).images.forEach((img) => onRemove(img));
			const res = await axiosPrivate.delete(`/pox/${id}`);
			return res;
		} catch (err) {
			message.error(err?.response?.data?.message || err?.message);
		}
	};

	const handleDelete = async (id) => {
		if (!window.confirm('Are you sure you want to delete this location')) return;
		await deletePox(id);
		message.success('Delete successful');
		await fetchData();
	};

	const columns = [
		{
			title: 'Pox Id',
			dataIndex: 'id',
			key: 'id',
		},
		{
			title: 'Pox Name',
			dataIndex: 'name',
			key: 'name',
		},
		{
			title: 'Pox Type',
			dataIndex: 'type',
			filters: [
				{
					text: 'Plot',
					value: 'Plot',
				},
				{
					text: 'Flat',
					value: 'Flat',
				},
				{
					text: 'Estab',
					value: 'Estab',
				},
			],
			onFilter: (value, record) => record.type.indexOf(value) === 0,
		},
		{
			title: 'Date Published',
			dataIndex: 'date',
			key: 'date',
			render: (text) =>
				new Date(text).toLocaleDateString('en-us', {
					year: 'numeric',
					month: 'short',
					day: 'numeric',
				}),
		},
		{
			title: 'Status',
			dataIndex: 'status',
			key: 'status',
		},
		{
			title: 'Action',
			key: 'action',
			render: (text, record) => (
				<Space size="middle">
					<Link to={`/poxes/${record.key}`}>View</Link>
					{!user?.isAdmin && (
						<Button type="danger" onClick={() => handleDelete(record.key)}>
							Delete
						</Button>
					)}
				</Space>
			),
		},
	];

	return (
		<Fragment>
			<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
				<PageHeader
					className="site-page-header"
					onBack={() => navigate(-1)}
					title={location.pathname === '/poxes/owned' ? 'My Poxes' : 'All Poxes'}
				/>
				{location.pathname === '/poxes' && !user?.Admin && (
					<Button
						type="primary"
						style={{ marginRight: 20 }}
						icon={<PlusOutlined />}
						onClick={() => navigate('/poxes/new')}
					>
						Add New
					</Button>
				)}
			</div>
			{user?.isAdmin ? (
				<PoxAdmin />
			) : (
				<div style={{ margin: '0 20px' }}>
					<Table columns={columns} dataSource={data} loading={isLoading} />
				</div>
			)}
		</Fragment>
	);
}
