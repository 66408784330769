import { useEffect, useState, Fragment } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Button,  PageHeader, Row, Col, message, Typography ,Input,Image} from 'antd';
import useAxiosPrivate from 'hooks/use-axios-private';
import Mapinfo from 'components/LeafletMap/Mapinfo.jsx';

export default function Verifpox() {
	const { id } = useParams();
	const navigate = useNavigate();
	const [data, setData] = useState(null);
	const axiosPrivate = useAxiosPrivate();
	const img=[
		'https://images.unsplash.com/5/unsplash-kitsune-4.jpg?ixlib=rb-0.3.5&ixid=eyJhcHBfaWQiOjEyMDd9&s=bc01c83c3da0425e9baa6c7a9204af81',
		'https://images.unsplash.com/5/unsplash-kitsune-4.jpg?ixlib=rb-0.3.5&q=85&fm=jpg&crop=entropy&cs=srgb&ixid=eyJhcHBfaWQiOjEyMDd9&s=ce40ce8b8ba365e5e6d06401e5485390',
		'https://images.unsplash.com/5/unsplash-kitsune-4.jpg?ixlib=rb-0.3.5&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=1080&fit=max&ixid=eyJhcHBfaWQiOjEyMDd9&s=fb86e2e09fceac9b363af536b93a1275',
		'https://images.unsplash.com/5/unsplash-kitsune-4.jpg?ixlib=rb-0.3.5&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=400&fit=max&ixid=eyJhcHBfaWQiOjEyMDd9&s=dd060fe209b4a56733a1dcc9b5aea53a',
		'https://images.unsplash.com/5/unsplash-kitsune-4.jpg?ixlib=rb-0.3.5&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=200&fit=max&ixid=eyJhcHBfaWQiOjEyMDd9&s=50827fd8476bfdffe6e04bc9ae0b8c02',
	];

	// delete location

	// toggle status
	const toggleStatus = async () => {
		const status = data?.status === 'active' ? 'deactivated' : 'active';
		try {
			await axiosPrivate.patch(`/pox/${id}`, { status });
			message.info(`Pox status changed to ${status}`);
			setData((prev) => ({
				...prev,
				status,
			}));
		} catch (err) {
			message.error(err?.response?.data?.message || err?.message);
		}
	};

	useEffect(() => {
		const fetchData = async () => {
			try {
				const res = await axiosPrivate.get(`/pox/${id}`);
				setData(res.data.pox);
				console.log(res.data.pox);
			} catch (error) {
				message.error(error?.response?.data?.message || error.message);
			}
		};
		fetchData();

		return () => {
			setData(null);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Fragment>
			<div style={{ display: 'flex', alignItems: 'center', marginBottom: 20 }}>
				<PageHeader
					className="site-page-header"
					style={{ flexGrow: 1 }}
					onBack={() => navigate(-1)}
					title="Verified Poxes"
				/>
			</div>

			<Row>
				<Col span={12} style={{ padding: '0 24px' }}>
					<h2 className="site-page-header">Pox Details</h2>
					<p style={{display:"flex",alignItems:"center"}}>
						<Typography.Text  className='pox-detail'>Property Name</Typography.Text><Typography.Text  className='pox-detail-value'>:&nbsp;{data?.name}</Typography.Text>
						
					</p>
					<p style={{display:"flex",alignItems:"center"}}>
						<Typography.Text className='pox-detail'>Property Type</Typography.Text><Typography.Text className='pox-detail-value'>:&nbsp;{data?.type}</Typography.Text>
						
					</p>
					<div>
						<div style={{ display: 'flex' }}>
							<div style={{ display: 'flex', flexDirection: 'column' }}>
								<Typography.Text className='pox-detail'>Publisher</Typography.Text>
								<Typography.Text className='pox-detail'>& Contact Details</Typography.Text>
							</div>
							:&nbsp;
							<div style={{ display: 'flex', flexDirection: 'column' }}>
								<h4 className='pox-detail-value'>{data?.publishedBy.displayName}</h4>
								<h4 className='pox-detail-value' style={{ marginTop: '-6px' }}>{data?.contact_number}</h4>
							</div>
						</div>
					</div>
					<p style={{display:"flex"}}>
						<Typography.Text className='pox-detail'>Rent & Deposit</Typography.Text><Typography.Text className='pox-detail-value'>:&nbsp;{data?.deposit_cost}</Typography.Text>
						
					</p>

					<p style={{ display: 'flex' }}>
						<Typography.Text className='pox-detail'>Address</Typography.Text><Typography.Text className='pox-detail-value'>:&nbsp;{data?.address}</Typography.Text>
						
					</p>
					<div>
						<h1>Gallery</h1>
						<div className="image-preview">
							<Image.PreviewGroup>
								{data?.images.map((image, id) => {
									return (
										<Image
											key={id}
											src={image}
											style={{
												display: id >= 3 ? 'none' : 'inline-block',
												borderRadius: '8px',
												margin: '10px',
												width: '170px',
											}}
										/>
									);
								})}
							</Image.PreviewGroup>

							{data && (
								<div
									style={{
										width: '169px',
										height: '113px',
										border: '1px solid #808080',
										borderRadius: '8px',
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center',
										margin: '10px',
										position: 'relative',
										top: '-133px',
										left: '189px',
									}}
								>
									+{data?.images.length - 3}
								</div>
							)}
						</div>
					</div>
				</Col>
				<Col span={12}>
					{/* map select area */}
					<div
						style={{
							width: '100%',
							height: '350px',
							backgroundColor: '#f2f3f8',
							marginBottom: 16,
						}}
					>
						<div style={{ display: 'flex' }}>
							<div style={{ display: 'flex' }}>
								<Typography.Text type="secondary">Status</Typography.Text>:&nbsp;{' '}
								{data?.status === 'active' ? (
									<h4 style={{ color: '#38CF2B' }}>{data?.status}</h4>
								) : (
									<h4 style={{ color: 'red' }}>{data?.status}</h4>
								)}
								<h4 style={{ color: '#4274C4',marginLeft:"5px" ,cursor:"pointer",}} onClick={toggleStatus}>Change Status</h4>{' '}
							</div>
						</div>
						<div
								style={{
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
									padding: '30px',
									width: '85%',
									height: '88.5%',
									border: '1px solid #808080',
									borderRadius: '10px',
								}}
							>
								{data && 
								<div
								style={{
									width: '100%',
									height: '100%',
									zIndex: 1,
									border: '1px solid #808080',
								}}
							>
								<Mapinfo position={data?.position} />
							</div>}
								
							</div>
						
							{data && (
							<div style={{ margin: '10px', width: '84%' }}>
								<div style={{ display: 'flex' }}>
									<h1
										style={{
											margin: '10px',
											fontWeight: '400',
											fontSize: '19px',
											lineHeight: '23px',
											color: '#17185C',
										}}
									>
										Add Recommended Price
									</h1>
									<div
										style={{
											margin: '9px',
											display: 'flex',
											flexDirection: 'column',
											width: '60%',
										}}
									>
										<Input
											placeholder="Rent"
											style={{
												margin: '5px',
												width: '90%',
												height: '50px',
												borderRadius: '5px',
											}}
										/>
										<Input
											placeholder="Discount"
											style={{
												margin: '5px',
												width: '90%',
												height: '50px',
												borderRadius: '5px',
											}}
										/>
									</div>
								</div>
								<div
									style={{
										margin: '10px',
										display: 'flex',
										justifyContent: 'center',
										margin: '10px',
									}}
								>
									<Button
										style={{
											width: '60%',
											height: '50px',
											background: '#4274C4',
											boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)',
											borderRadius: '10px',
											fontSize: '23px',
										}}
									>
										Confirm
									</Button>
								</div>
							</div>
						)}

					</div>
				</Col>
			</Row>
		</Fragment>
	);
}
