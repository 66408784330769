import { useSelector } from 'react-redux';
import { Outlet, Navigate, useLocation } from 'react-router-dom';
import { getUser } from 'redux/features/auth';

const RequireAuth = ({ requiresAdmin }) => {
	const user = useSelector(getUser);
	const location = useLocation();

	if (user?.isAdmin) {
		return <Outlet />;
	} else {
		if (user) {
			if (requiresAdmin) {
				return <Navigate to="/unathorized" state={{ from: location }} replace />;
			} else {
				return <Outlet />;
			}
		} else {
			return <Navigate to="/login" state={{ from: location }} replace />;
		}
	}
};

export default RequireAuth;
