import { useState, useEffect, Fragment } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import axios from 'apis/axios';
import { Table, Space, message, Input } from 'antd';
import { getUser } from 'redux/features/auth';
import "../Layout/assets/styles.scss"

const { Search } = Input;

export default function PoxAdmin() {
	const location = useLocation();
	const user = useSelector(getUser);

	const [data, setData] = useState([]);
	const [verifRequest, setVerifRequest] = useState(true);
	const [searchTerm,setSearchTerm]=useState('')
	const [verif, setVerif] = useState(false);
	const fetchData = async (owned = '') => {
		try {
			if (user.email === 'admin@ucpox.com') {
				
				await axios.get(`/pox`).then((res) => {
					console.log(res);
					setData(
						res.data.poxes.map((e) => ({
							key: e._id,
							id: e.id,
							name: e.name,
							type: e.type,
							date: e.createdAt,
							status: e.status,
							userId: e.publishedBy.displayName,
						}))
					);
				});
			}
		} catch (error) {
			message.error(error?.response?.data?.message || error?.message);
		}
	};
	const onSearch = (value) => {
			setSearchTerm(value)
			
	};
	// const onChange =(value)=>{
	// 	console.log(value)
	// }
	useEffect(() => {
		fetchData(location.pathname === '/poxes/owned' ? '&ownership=true' : '');

		return () => {
			setData([]);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location]);


	// delete location
	

	
	const columns = [
		{
			title: 'Pox Id',
			dataIndex: 'id',
			key: 'id',
		},
		{
			title: 'Pox Name',
			dataIndex: 'name',
			key: 'name',
		},
		{
			title: 'Pox Type',
			dataIndex: 'type',
			filters: [
				{
					text: 'Plot',
					value: 'Plot',
				},
				{
					text: 'Flat',
					value: 'Flat',
				},
				{
					text: 'Estab',
					value: 'Estab',
				},
			],
			onFilter: (value, record) => record.type.indexOf(value) === 0,
		},
		// {
		// 	title: 'Date Published',
		// 	dataIndex: 'date',
		// 	key: 'date',
		// 	render: (text) =>
		// 		new Date(text).toLocaleDateString('en-us', {
		// 			year: 'numeric',
		// 			month: 'short',
		// 			day: 'numeric',
		// 		}),
		// },
		{ title: 'Publisher Name', dataIndex: 'userId', key: 'userId' },
		{
			title: 'Status',
			dataIndex: 'status',
			key: 'status',
		},
		{
			title: 'Action',
			key: 'action',
			render: (text, record) => (
				<Space size="middle">
					<Link to={`/poxes/poxverif/${record.key}`}>View</Link>
				</Space>
			),
		},
	];
	const columns2 = [
		{
			title: 'Pox Id',
			dataIndex: 'id',
			key: 'id',
		},
		{
			title: 'Pox Name',
			dataIndex: 'name',
			key: 'name',
		},
		{
			title: 'Pox Type',
			dataIndex: 'type',
			filters: [
				{
					text: 'Plot',
					value: 'Plot',
				},
				{
					text: 'Flat',
					value: 'Flat',
				},
				{
					text: 'Estab',
					value: 'Estab',
				},
			],
			onFilter: (value, record) => record.type.indexOf(value) === 0,
		},

		{ title: 'Publisher Name', dataIndex: 'userId', key: 'userId' },
		{
			title: 'Status',
			dataIndex: 'status',
			key: 'status',
		},
		{
			title: 'Action',
			key: 'action',
			render: (text, record) => (
				<Space size="middle">
					<Link to={`/poxes/verifpox/${record.key}`}>View</Link>
				</Space>
			),
		},
	];

	const handleVerifRequest = () => {
		setVerifRequest(true);
		setVerif(false);
	};
	const handleVerif = () => {
		setVerifRequest(false);
		setVerif(true);
	};
	return (
		<Fragment>
			<div style={{ display: 'flex', flexDirection: 'column', width: '97%' }}>
				<div
					style={{
						width: '97.6%',
						height: '70px',
						borderRadius: '6px',
						background: '#FFFFFF',
						margin: '5px 20px',
					}}
				>
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							width: '100%',
						}}
					>
						<div
							style={{
								width: '50%',
								height: '50px',
								textAlign: 'center',
								background: verifRequest ? 'rgba(23, 24, 92, 0.1)' : 'white',
								borderRadius: '6px',
								margin: '10px',
								padding: '10px',
								cursor: 'pointer',
							}}
							onClick={handleVerifRequest}
						>
							<h1 className="adminpox-heading" style={{color:verifRequest?"#17185C":" #808080"}}>Pox Verification Request</h1>
						</div>
						<span
							style={{
								border: '1px solid #808080',
								width: '54px',
								height: '0px',
								transform: 'rotate(90deg)',
							}}
						></span>
						<div
							style={{
								width: '50%',
								height: '50px',
								textAlign: 'center',
								background: verif ? 'rgba(23, 24, 92, 0.1)' : 'white',
								borderRadius: '6px',
								margin: '10px',
								padding: '10px',
								cursor: 'pointer',
							}}
							onClick={handleVerif}
						>
							<h1 className="adminpox-heading" style={{color:verif?"#17185C":" #808080"}}>Verified Poxes </h1>
						</div>
					</div>
				</div>
				<div style={{ display: 'flex', justifyContent: 'center', margin: '20px' }}>
					{' '}
					<Space>
						<Search
							placeholder="Enter Region, Pox name,Publisher"
							size="large"
							enterButton
							style={{ width: 604 }}
							onSearch={(e) => {
								onSearch(e);
							}}
							onChange={(e)=>setSearchTerm(e.target.value)}
						/>
					</Space>
					{/* <h1
						style={{
							color: '#17185c',
							width: '80px',
							height: '30px',
							textAlign: 'center',
							background: 'rgba(23, 24, 92, 0.1)',
							borderRadius: '6px',
							margin: '10px',
						}}
					>
						Sort by
					</h1> */}
				</div>
			</div>
			{verifRequest && (
				<div style={{ display: 'flex', flexDirection: 'column' }}>
					<h1 style={{ margin: '20px' }}>Recently Added</h1>
					<div style={{ margin: '0 20px', width: '95%' }}>
						<Table columns={columns} dataSource={data.filter((item)=>{
							return item?.name.toLowerCase().includes(searchTerm.toLowerCase())
						})} loading={data.length>0 ? false:true}/>
					</div>
				</div>
			)}
			{verif && (
				<div style={{ display: 'flex', flexDirection: 'column' }}>
					<h1 style={{ margin: '20px' }}>Recently Verified</h1>
					<div style={{ margin: '0 20px', width: '95%' }}>
						<Table columns={columns2} dataSource={data.filter((item)=>{
							return item?.name.toLowerCase().includes(searchTerm.toLowerCase())
						})} loading={data.length>0 ? false:true} />
					</div>
				</div>
			)}
		</Fragment>
	);
}
